import React from 'react'
import { FormattedMessage } from 'react-intl'
import { authInterceptedAxios, appInterceptedAxios } from './interceptors'

export function listGames () {
  return authInterceptedAxios({
    method: 'get',
    url: '/game/user-list-games',
    loadKey: <FormattedMessage id='api.game.loader.listGames' />,
    isInterruptive: true
  })
}

export function playGame ({ token }) {
  return authInterceptedAxios({
    method: 'post',
    url: '/game/user-play-game',
    data: { token },
    loadKey: <FormattedMessage id='api.game.loader.playGame' />,
  })
}

export function listGamesTip () {
  return appInterceptedAxios({
    method: 'get',
    url: '/game/user-list-games-tip',
    loadKey: <FormattedMessage id='api.game.loader.gametips' />,
    isInterruptive: true
  })
}