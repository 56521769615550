import * as promotionService from '../services/promotion.api'
import { SET_PROMOTIONS, SET_CAROUSEL_IMAGE } from '../reducers/promotion.reducer'

export function listPromotions ({ locale }) {
  return async dispatch => {
    const data = await promotionService.listPromotions({ locale })

    dispatch({
      type: SET_PROMOTIONS,
      payload: { posts: data }
    })
  }
}

export function listCarouselImages () {
  return async dispatch => {
    const data = await promotionService.listCarouselImages()
    dispatch({
      type: SET_CAROUSEL_IMAGE,
      payload: { carouselImages: data }
    })
  }
}