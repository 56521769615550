import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Helmet } from 'react-helmet'
import { FormattedMessage } from 'react-intl';

import { Page, PageHeader, Breadcrumb, Crumb, ToggleGroup, Tab } from '@framework'

import ProfilePage from './Profile'
import HistoryPage from './History'
import BonusPage from './Bonus'

const TAB_PROFILE = 'tab-profile'
const TAB_HISTORY = 'tab-history'
const TAB_BONUS = 'tab-bonus'

export class ProfileOverviewPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      page: TAB_PROFILE
    }
  }

  handleToggle (selected) {
    this.setState({
      page: selected
    })
  }

  renderSelectedPage () {
    const { page } = this.state
    switch (page) {
      case TAB_PROFILE:
        return <ProfilePage />
      case TAB_HISTORY:
        return <HistoryPage />
      case TAB_BONUS:
        return <BonusPage/>
      default:
        return null
    }
  }

  render () {
    return (
      <Page paperWhite className='page-withdrawal'>
        <PageHeader>
          <ToggleGroup branded
            selected={this.state.page}
            onChange={(selected) => this.handleToggle(selected)}>
            <Tab value={TAB_PROFILE}>
              <FormattedMessage id='page.profile.overview.tab.profile' />
            </Tab>
            <Tab value={TAB_HISTORY}>
              <FormattedMessage id='page.profile.overview.tab.transaction' />
            </Tab>
            <Tab value={TAB_BONUS} showBadge={this.props.bonus.length > 0}>
              <FormattedMessage id='page.profile.overview.tab.bonus' />
            </Tab>
          </ToggleGroup>
        </PageHeader>
        <Helmet title='Spins2win: Profile' />
        <Breadcrumb>
          <Crumb to='/'>Home</Crumb>
          <Crumb>Profile</Crumb>
        </Breadcrumb>
        {this.renderSelectedPage()}
    </Page>
    )
  }
}

export default connect(
  ({ wallet }) => ({
    bonus: wallet.bonusTypes
  }),
  dispatch => bindActionCreators({
  }, dispatch)
)(ProfileOverviewPage)

