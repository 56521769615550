export default {
  label: 'English',
  locale: 'en-US',
  messages: {
    'common.input.submit': 'Submit',
    'common.input.cancel': 'Cancel',
    'component.form.bindbank.input.bankname': 'Bank Name',
    'component.form.bindbank.input.accountnumber': 'Account Number',
    'component.form.bindbank.input.accountname': 'Account Name',
    'component.form.bindbank.input.submit': 'Add Bank',
    'component.modal.birthdate.header': 'Fill in your birthdate',
    'component.modal.birthdate.body': 'Please update your birthdate to receive promotion',
    'component.modal.birthdate.input.date': 'Birthdate',
    'component.modal.resetpassword.header': 'Password Reset Success',
    'component.modal.resetpassword.body': 'A new password will set in a moment.',
    'component.modal.spinsuccess.header.win': 'Congratulations!',
    'component.modal.spinsuccess.header.lose': 'Feel So Sorry!',
    'component.modal.spinsuccess.body.win': 'Well Spin! You\'ve just win',
    'component.modal.spinsuccess.body.win-score': 'score',
    'component.modal.spinsuccess.body.win-freespin': 'a free spin',
    'component.modal.spinsuccess.body.lose': 'Don\'t be upset! You will be better lucky next time',
    'component.modal.language.header': 'Please Select Language',
    'component.table.realtimeboard.tab.deposit': 'Deposit',
    'component.table.realtimeboard.tab.withdrawal': 'Withdraw',
    'component.table.realtimeboard.tab.games': 'Tips',
    'component.table.realtimeboard.tab.sub.title': 'Real Time',
    'component.table.realtimeboard.tab.tip.table.notice': '10 games that has the most player will be displayed first in the system',
    'component.table.realtimeboard.tab.tip.refresh': 'Refresh In every hour',
    'component.table.realtimeboard.tab.tip.table.column.1': 'Games',
    'component.table.realtimeboard.tab.tip.table.column.2': 'Online User',
    'component.table.realtimeboard.tab.tip.table.column.3': 'Jacpot Rate',
    'component.table.realtimeboard.tab.tip.table.column.4': 'FreeGame Rate',
    'component.authprompt.label.language': 'Language',
    'component.authprompt.button.register': 'Register',
    'component.authprompt.button.login': 'Login',
    'component.authprompt.button.download': 'Download',
    'component.livechat.button': '24 Hr Live Chat',
    'component.spinwheel.balance': 'Game Point Balance',
    'component.spinwheel.point': 'Points To Play',
    'component.walletbalance.label.short': 'Balance',
    'component.walletbalance.label.long': 'Your balance is',
    'component.modal.otp.header': 'Dear Member Spins2win',
    'component.modal.otp.msg': 'Please wait for 5 minutes to request again, if still not recceived otp code',
    'component.modal.has.otp.msg': 'You can use previous OTP to login, please retrieve from your old msg',
    'page.app.tab.deposit': 'Deposit',
    'page.app.tab.withdraw': 'Withdraw',
    'page.app.tab.wheel': 'Wheel',
    'page.app.tab.promotion': 'Promotion',
    'page.app.tab.profile': 'Profile',
    'page.login.helmet.title': 'Spins2win: Register / Login',
    'page.login.body.title': 'Members Login',
    'page.login.input.phone': 'Phone Number',
    'page.login.input.submit': 'Get OTP',
    'page.verifyotp.text.header': 'Key in One Time Password (OTP)',
    'page.verifyotp.text.p': 'Please check SMS for OTP',
    'page.verifyotp.text.resend.countdown': 'Resend in',
    'page.verifyotp.text.resend': 'Resend',
    'page.verifyotp.input.otp': 'One Time Password',
    'page.verifyotp.input.referral': 'Referral Phone Number (optional)',
    'page.verifyotp.input.submit': 'Login',
    'page.deposit.bonus.warning': 'No Bonus will be display due to exceed amount of balance',
    'page.deposit.cash.notice.txt1': 'Please Inquiry ',
    'page.deposit.cash.notice.txt2': 'Bank Account Detail ',
    'page.deposit.cash.notice.txt3': 'Through Live Chat.',
    'page.deposit.cash.input.deposit': 'Deposit Amount',
    'page.deposit.cash.input.reference': 'Reference ID',
    'page.deposit.cash.input.date': 'Deposit Date',
    'page.deposit.online.help2pay.copy.right': 'Online Payment Gateway Support by Help2pay Copy Right $year',
    'page.deposit.online.input.deposit': 'Deposit Amount',
    'page.deposit.online.input.nobonus': 'No Bonus',
    'page.deposit.overview.tab.online': 'Online Deposit',
    'page.deposit.overview.tab.cash': 'Cash Deposit',
    'page.deposit.overview.text.minimum': 'Minimum Deposit Amount',
    'page.deposit.overview.text.maximum': 'Maximum Deposit Amount',
    'page.profile.profile.title': 'Update Your Profile',
    'page.profile.history.input.startdate': 'Start Date',
    'page.profile.history.input.enddate': 'End Date',
    'page.profile.history.input.loadmore': 'Load More',
    'page.profile.history.input.search': 'Search',
    'page.profile.overview.tab.profile': 'Profile',
    'page.profile.overview.tab.transaction': 'History',
    'page.profile.overview.tab.bonus': 'Bonus',
    'page.profile.profile.input.id': 'Mega ID',
    'page.profile.profile.input.password': 'Mega Password',
    'page.profile.bonus.label.empty': 'You Don\'t have bonus yet',
    'page.profile.bonus.label.referralBonus': 'Referral Bonus',
    'page.profile.bonus.label.rebateBonus': 'Rebate Bonus',
    'page.profile.bonus.input.claim': 'Claim',
    'page.profile.bonus.input.withdraw': 'Withdraw',
    'page.profile.profile.button.changepassword': 'Change Mega Password',
    'page.profile.profile.button.logout': 'Logout',
    'page.profile.profile.input.error': 'Password must be 6 length, at least one capital and no consenquence number',
    'page.withdrawal.overview.tab.withdraw': 'Withdrawal',
    'page.withdrawal.overview.tab.bindbank': 'Bind Bank',
    'page.withdrawal.overview.text.minimum': 'Minimum Withdraw Amount',
    'page.withdrawal.overview.text.maximum': 'Maximum Withdraw Amount',
    'page.withdrawal.withdrawal.input.amount': 'Withdrawal Amount',
    'page.withdrawal.withdrawal.input.submit': 'Request Withdrawal',
    'page.withdrawal.withdrawal.text.instruction': 'Add a bank to request withdrawal',
    'api.agent.loader.addBank': 'Adding bank',
    'api.agent.loader.getAccountInfo': 'Fetching Account Info',
    'api.agent.loader.listTransactions': 'Listing Transactions',
    'api.agent.loader.syncScore': 'Syncing Score',
    'api.agent.loader.resetPassword': 'Reseting Password',
    'api.agent.loader.listPublicTransactions': 'Fetching Real-Time Transaction Data',
    'api.agent.loader.listBonusType': 'Fetching Bonus Type',
    'api.agent.loader.claimBonusType': 'Claimming Bonus Type',
    'api.agent.loader.withdrawBonusType': 'Withdrawing Bonus Type',
    'api.auth.loader.sendOtp': 'Requesting One Time Password',
    'api.auth.loader.verifySignUp': 'Signing Up',
    'api.auth.loader.login': 'Logging In',
    'api.auth.loader.refreshToken': 'Refreshing Token',
    'api.auth.loader.getProfile': 'Building Your Workspace',
    'api.auth.loader.updateProfile': 'Updating Profile',
    'api.auth.loader.logout': 'Signing Out',
    'api.game.loader.listGames': 'Fetching list of games',
    'api.game.loader.playGame': 'Spinning Wheel',
    'api.game.loader.gametips': 'Listing Game Tips',
    'api.messaging.loader.getAnnouncement': 'Getting Announcement',
    'api.payment.loader.deposit': 'Connecting to Help2Pay',
    'api.payment.loader.depositCash': 'Submitting Deposit Request',
    'api.payment.loader.requestWithdrawal': 'Submitting Withdrawal Request',
    'api.payment.loader.listWithdrawalRequest': 'Listing Withdrawal Request',
    'api.payment.loader.listBonuses': 'Listing Bonuses',
    'api.payment.loader.listOrders': 'Listing Transactions',
    'api.sync.loader.syncTurnOver': 'Syncing Turnover',
    'api.uploader.loader.uploadFile': 'Uploading file',
    'e001': 'Phone number format is not correct. \n eg.0127543455',
    'e002': 'OTP code is not valid',
    'e003': 'Deposit minimum amount should be MYR 50',
    'e004': 'Deposit minimum amount should be MYR 30',
    'e005': 'Withdrawal minimum amount should be MYR 50',
    'e006': 'Insufficient balance to withdrawal',
    'e007': 'Insufficient point to play game',
    'e008': 'Invalid referral user',
    'e009': 'Withdraw request allow at a time',
    'e010': 'Lucky Wheel is not valid at the moment',
    'e011': 'Please Attach a image',
    'e012': 'System down at the moment',
  }
}