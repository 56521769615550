import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { Flex, Image } from '@framework'
import { pathContain } from '@helpers/routes'

import './Navbar.scss'

export const Navbar = props => {
  const {
    children,
    className,
    ...attr
  } = props

  return (
    <ul className={`
      af-navbar ${className}
    `} {...attr}>
      {children}
    </ul>
  )
}

export const NavbarItem = props => {
  const { icon, to, children, showSelected, showBadge, ...attr } = props

  if (to) {
    return (
      <Link to={to} className={classNames({
        'af-navbar-item': true,
        '--selected': pathContain(showSelected)
      })} {...attr}>
        <Flex column>
          <div className='__icon-container'>
            { showBadge && <span className='badge'></span> }
            <Image className='__icon' src={`/icons/${icon}.svg`} />
          </div>
          <span className='__label'>{children}</span>
        </Flex>
      </Link>
    )
  }

  return (
    <div className={classNames({
      'af-navbar-item': true,
      '--selected': pathContain(showSelected)
    })} {...attr}>
      <Flex column>
        <div className='__icon-container'>
          <Image className='__icon' src={`/icons/${icon}.svg`} />
        </div>
        <span className='__label'>{children}</span>
      </Flex>
    </div>
  )
}
