import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Modal } from '@framework/'
import { closeModal } from '../store/actions/app.action'

import { SuccessModal, ErrorModal, BirthdateModal, ResetPasswordSuccessModal, SpinSuccessModal, LanguageModal, MaintenanceModal, MessageModal, VideoModal } from './Modals'

export const SUCCESS_MODAL = 'SUCCESS'
export const ERROR_MODAL = 'ERROR'
export const BIRTHDATE_MODAL = 'BIRTHDATE'
export const RESET_PASSWORD_SUCCESS_MODAL = 'RESET_PASSWORD_SUCCESS_MODAL'
export const SPIN_SUCCESS_MODAL = 'SPIN_SUCCESS_MODAL'
export const LANGUAGE_MODAL = 'LANGUAGE_MODAL'
export const MAINTENANCE_MODAL = 'MAINTENANCE_MODAL'
export const MSG_MODAL = 'MSG_MODAL'
export const VIDEO_MODAL = 'VIDEO_MODAL'


class ModalWrapper extends Component {
  constructor (props) {
    super(props)
    this.hideModal = this.hideModal.bind(this)
  }

  componentDidUpdate () {
    if (this.props.modal) {
      this.modal.show()
    }
  }

  hideModal () {
    setTimeout(() => {
      this.props.closeModal(null)
    }, 400)
  }

  renderModal () {
    switch (this.props.modal) {
      case SUCCESS_MODAL:
        return <SuccessModal modal={this.modal} />
      case ERROR_MODAL:
        return <ErrorModal modal={this.modal} />
      case BIRTHDATE_MODAL:
        return <BirthdateModal modal={this.modal} />
      case RESET_PASSWORD_SUCCESS_MODAL:
        return <ResetPasswordSuccessModal modal={this.modal} />
      case SPIN_SUCCESS_MODAL:
        return <SpinSuccessModal modal={this.modal} />
      case LANGUAGE_MODAL:
        return <LanguageModal modal={this.modal}/>
      case MAINTENANCE_MODAL:
        return <MaintenanceModal modal={this.modal}/>
      case MSG_MODAL:
       return <MessageModal modal={this.modal}/>
      case VIDEO_MODAL:
        return <VideoModal modal={this.modal}/>
      default:
    }
  }

  render () {
    const { config } = this.props

    return (
      <Modal onMount={ref => (this.modal = ref)}
        onHide={this.hideModal}
        nonDismissable={config.nonDismissable}
        forceNormalLayout>
        {this.renderModal()}
      </Modal>
    )
  }
}

function mapStatetoProps ({ app }) {
  return {
    modal: app.modal.current,
    config: app.modal.config
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({ closeModal }, dispatch)
}

export default connect(mapStatetoProps, mapDispatchToProps)(ModalWrapper)
