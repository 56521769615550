export default {
  label: '中文',
  locale: 'cn',
  messages: {
    'common.input.submit': '提交',
    'component.form.bindbank.input.bankname': '银行名字',
    'component.form.bindbank.input.accountnumber': '账户号码',
    'component.form.bindbank.input.accountname': '用户名字',
    'component.form.bindbank.input.submit': '绑定银行户口',
    'component.modal.birthdate.header': '请填入您的生日日期',
    'component.modal.birthdate.body': '请填入生日日期以的到促销',
    'component.modal.birthdate.input.date': '生日日期',
    'component.modal.resetpassword.header': '更新密码成功',
    'component.modal.resetpassword.body': '需要一些时间反应出新密码',
    'component.modal.spinsuccess.header': '恭喜！',
    'component.modal.spinsuccess.body': '您获得了奖金 ',
    // 'component.modal.language.header': '',
    'component.table.realtimeboard.tab.deposit': '充值',
    'component.table.realtimeboard.tab.withdrawal': '提款',
    'component.table.realtimeboard.tab.games': '游戏',
    'component.authprompt.label.language': '语言',
    'component.authprompt.button.register': '注册',
    'component.authprompt.button.login': '登入',
    'component.livechat.button': '24小时在线客服',
    'component.spinwheel.submit': '旋转',
    'component.walletbalance.label.short': '余额',
    'component.walletbalance.label.long': '余额',
    'page.app.tab.deposit': '充值',
    'page.app.tab.withdraw': '提款',
    'page.app.tab.wheel': '游戏',
    'page.app.tab.promotion': '促销',
    'page.app.tab.profile': '个人',
    'page.login.helmet.title': 'OPS: 注册 / 登录',
    'page.login.body.title': '会员登录',
    'page.login.input.phone': '电话号码',
    'page.login.input.submit': '获发 OTP',
    'page.verifyotp.text.header': '请输入一次性密码（OTP）',
    'page.verifyotp.text.p': '我们正在发送OTP，您可以查看 SMS 索取 OTP',
    'page.verifyotp.input.otp': '一次性密码（OTP）',
    'page.verifyotp.input.submit': '登录',
    'page.deposit.cash.input.deposit': '充值金额',
    'page.deposit.cash.input.reference': 'Reference ID',
    'page.deposit.cash.input.date': '充值日期',
    'page.deposit.online.input.deposit': '充值金额',
    'page.deposit.online.input.nobonus': '无需奖金',
    'page.deposit.overview.tab.online': '线上充值',
    'page.deposit.overview.tab.cash': '银行转送',
    'page.deposit.overview.text.minimum': '最小充值金额',
    'page.deposit.overview.text.maximum': '最大充值金额',
    'page.profile.history.input.startdate': '开始日期',
    'page.profile.history.input.enddate': '结束日期',
    'page.profile.history.input.loadmore': '加载更多',
    'page.profile.overview.tab.profile': '个人资料',
    'page.profile.overview.tab.transaction': '交易',
    'page.profile.profile.input.id': 'Mega ID',
    'page.profile.profile.input.password': 'Mega 密码',
    'page.profile.profile.button.changepassword': '更换 Mega 密码',
    'page.profile.profile.button.logout': '登出',
    'page.withdrawal.overview.tab.withdraw': '提款',
    'page.withdrawal.overview.tab.bindbank': '绑定银行资料',
    'page.withdrawal.overview.text.minimum': '最小充值金额',
    'page.withdrawal.overview.text.maximum': '最大充值金额',
    'page.withdrawal.withdrawal.input.amount': '提出金额',
    'page.withdrawal.withdrawal.input.submit': '申请提款',
    'page.withdrawal.withdrawal.text.instruction': '绑定银行户口后，才可申请提出款额',
    'api.agent.loader.addBank': '正在绑定银行户口',
    'api.agent.loader.getAccountInfo': '加载账户资料中',
    'api.agent.loader.listTransactions': '正在载入交易记录',
    'api.agent.loader.syncScore': '正在同步您的分数',
    'api.agent.loader.resetPassword': '重设密码中',
    'api.agent.loader.listPublicTransactions': '加载实时交易数据',
    'api.auth.loader.sendOtp': '正在发送一次性密码 OTP',
    'api.auth.loader.verifySignUp': '正在注册您的账户',
    'api.auth.loader.login': '登入中',
    'api.auth.loader.refreshToken': '冲刷代币中',
    'api.auth.loader.getProfile': '正在建立您的会员区',
    'api.auth.loader.updateProfile': '更新个人账户中',
    'api.auth.loader.logout': '登出中',
    'api.game.loader.listGames': '正在加载游戏',
    'api.game.loader.playGame': '正在旋转轮盘',
    'api.messaging.loader.getAnnouncement': '加载通告中',
    'api.payment.loader.deposit': '正联系 Help2Pay 服务中',
    'api.payment.loader.depositCash': '正在提交充值申请',
    'api.payment.loader.requestWithdrawal': '正在提交提款申请',
    'api.payment.loader.listWithdrawalRequest': '加载提款历史中',
    'api.payment.loader.listBonuses': '加载奖金数据',
    'api.payment.loader.listOrders': '加载交易数据',
    'api.sync.loader.syncTurnOver': '正早同步 Turnover',
    'api.uploader.loader.uploadFile': '上载文件中'
  }
}