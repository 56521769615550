import { SUCCESS_MODAL, ERROR_MODAL } from '@components/ModalWrapper'
// ActionTypes
export const START_INT_LOADING = '[APP] Start Interruptive Loading'
export const END_INT_LOADING = '[APP] End Interruptive Loading'
export const START_STACK_LOADING = '[APP] Start Stack Loading'
export const END_STACK_LOADING = '[APP] End Stack Loading'
export const OPEN_MODAL = '[APP] Open Modal'
export const CLOSE_MODAL = '[APP] Close Modal'
export const UPDATE_SUCCESS = '[APP] Update Success'
export const UPDATE_ERROR = '[APP] Update Error'
export const DO_NOTHING = '[APP] Do Nothing'

// For testing
export const INIT = '[APP] Init State'

const initialState = {
  loadStack: [],
  intLoadStack: [],
  modal: {
    current: null,
    config: {
      nonDismissable: false
    },
    bundleMsg: {
      header: 'Wow!',
      message: 'Good Luck to you. All the best',
      closeButtonText: 'OK'
    }
  },
  error: null,
  success: {
    header: 'This is great!',
    message: 'Succesfully updated!',
    closeButtonText: 'Got it'
  }
}

export default function (state = initialState, { payload, type, app: init }) {
  let updatedStack
  switch (type) {
    case START_INT_LOADING:
      return { ...state,
        intLoadStack: [...state.intLoadStack, payload] }
    case END_INT_LOADING:
      updatedStack = state.intLoadStack.filter(item => item !== payload)
      return { ...state,
        intLoadStack: updatedStack }
    case START_STACK_LOADING:
      return { ...state,
        loadStack: [...state.loadStack, payload] }
    case END_STACK_LOADING:
      updatedStack = state.loadStack.filter(item => item !== payload)
      return { ...state,
        loadStack: updatedStack }

    case OPEN_MODAL: {
      const { modal, config, bundleMsg } = payload
      return {
        ...state,
        modal: {
          ...state.modal,
          current: modal,
          bundleMsg: bundleMsg ? bundleMsg : initialState.modal.bundleMsg,
          config: typeof config === 'object' ? { ...config } : initialState.modal.config
        }
      }
    }

    case CLOSE_MODAL: {
      return {
          ...state,
        modal: initialState.modal
      }
    }

    case UPDATE_SUCCESS: {
      return {
        ...state,
        modal: {
          current: SUCCESS_MODAL,
          config: initialState.modal.config
        },
        success: {
          ...state.success,
          ...payload
        }
      }
    }

    case UPDATE_ERROR: {
      return {
        ...state,
        modal: {
          current: ERROR_MODAL,
          config: initialState.modal.config
        },
        error: payload
      }
    }

    case INIT:
      return { ...state,
        ...init }
    case DO_NOTHING:
    default:
      return state
  }
}
