import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import { FormattedMessage } from 'react-intl';
import uniqueId from 'lodash.uniqueid'
import { PageHeader, H2, H3, Tab, Tabular, ToggleGroup, Image, P } from '@framework'
import { listTransactionAnnouncement, listGamesTip } from '@store/actions/announcement.action'
import OurValue from '@components/OurValue'
import './RealTimeBoard.scss'
import { randomRangeProb, randomRangeWhole } from '@helpers/utils';
import { GAMES_COLUMN } from '@constants/gamesTip';

const TAB_DEPOSIT = 'deposit';
const TAB_WITHDRAWAL = 'withdrawal';
const TAB_GAMES = 'games';

const TYPE_FREE_GAME = 'freeGameRate';
const TYPE_JACKPOT = 'jackpotRate';


class RealTimeBoard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterBy: 'deposit',
      filteredAnnouncements: [],
      count: 0,
      gamesTip: [],
      tab: {
        deposit: 'Deposit',
        withdrawal: 'Withdrawal',
        games: 'Tips',
        freeGameRate: 'FREE GAME RATE',
        jackpotRate: 'HACKPOT RATE'
      },
      selectedDetail: {
        gameName: '',
        type: '',
      },
      today: new Date(),
      showDetail: false,
      isDisabled: false,
      itemsDetail: [],
      intervalId: null
    }
  }

  componentDidMount() {
    if (!this.props.isConnecting) {
      // this.props.listTransactionAnnouncement();
      this.props.listGamesTip();
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  generateRate(min, max, isProb) {
    return isProb ? randomRangeProb(min, max) : randomRangeWhole(min, max);
  }

  getIndex() {
    const m = moment(this.state.today);
    const hour = Number(this.state.today.getHours()) - 15;

    const hourIndex = hour < 0 ? 24 + hour : Number(this.state.today.getHours());

    const minitIndex = m.format('mm') < 30 ? 0 : 1;
    const index = (hourIndex * 2) + minitIndex;
    return index
  }

  getNewRate(rates, interval, isProb) {
    const hourIndex = this.getIndex();
    return rates.map((rate, index) => {
      if (index === hourIndex) {
        const min = (rate - interval) < 0 ? rate : rate - interval;
        const max = (rate + interval) < 0 ? rate : rate + interval;
        const newRate = this.generateRate(min, max, isProb);
        return newRate
      }
      return rate;
    })
  }

  updateInterval() {
    const isDisabled = this.props.games[0].freeGameRate.every(rate => rate === 0);
    this.setState({ isDisabled });

    if (!isDisabled) {
      const intervalId = setInterval(() => {
        const gamesTip = this.props.games.map(game => {
          const freeGameRate = this.getNewRate(game.freeGameRate, 10, true);
          const jackpotRate = this.getNewRate(game.jackpotRate, 10, true);
          const onlineUser = this.getNewRate(game.onlineUser, 100, false);

          return {
            ...game,
            freeGameRate,
            jackpotRate,
            onlineUser
          };
        })


        const index = this.getIndex();
        gamesTip.sort((a, b) => a.onlineUser[index] > b.onlineUser[index] ? -1 : b.onlineUser[index] > a.onlineUser[index] ? 1 : 0)
        this.setState({ gamesTip })
      }, 10000)
      this.setState({ intervalId });
    }

  }

  componentDidUpdate(prevProps, prevState) {
    const { announcements, games, announcementsCount } = this.props;

    if ((prevState.filteredAnnouncements.length === 0 && announcements.length > 0 && !this.state.showDetail) || (announcementsCount !== prevState.count && this.state.filterBy !== TAB_GAMES)) {
      this.handleFilterChange(this.state.filterBy)
    }

    if (prevState.gamesTip.length === 0 && games.length > 0 && this.state.showDetail) {
      this.handleFilterChange(this.state.filterBy)
    }
  }

  handleFilterChange(filterBy) {
    const { announcements, games, announcementsCount } = this.props

    switch (filterBy) {
      case TAB_GAMES:
        this.updateInterval();
        this.setState({ filterBy, gamesTip: games })
        break;
      default:
        clearInterval(this.state.intervalId);
        let filteredAnnouncements = announcements
          .filter(item => item.remark === filterBy)
          .filter(item => item.phoneNumber.startsWith('01'))
        filteredAnnouncements = filteredAnnouncements.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        filteredAnnouncements = filteredAnnouncements.slice(0, 6);
        filteredAnnouncements = filteredAnnouncements.map(item => ({
          ...item,
          score: `RM ${Math.abs(item.score).toFixed(2)}`
        }));
        this.setState({ filterBy, filteredAnnouncements, count: announcementsCount })
        break

    }

  }

  renderTableContent() {
    const { filteredAnnouncements, filterBy } = this.state
    switch (filterBy) {
      case TAB_GAMES:
        return this.renderGameTips();

      default:
        return (
          <Tabular branded data={filteredAnnouncements}
            staticColumns={[
              {
                headerAccessor: "phoneNumber",
                subheaderAccessor: "createdAt",
                Cell: row => [
                  <p key={uniqueId()} className='header'>{row.headerValue}</p>,
                  <p key={uniqueId()} className='subheader'>{moment(new Date(row.subheaderValue)).format('YYYY-MM-DD h:mm:ss a')}</p>
                ]
              }
            ]}
            dynamicColumns={[
              {
                Header: "Score",
                accessor: "score"
              }
            ]} />
        )

    }
  }

  getCorrectionHourIndex(curr = 0, index = 0, interval = 0) {
    let x = curr - interval - index
    return x < 0 ? 48 + x : x;
  }

  renderHalf(m, isHalf) {
    return isHalf ?
      <p className='hours'>{m.subtract(1, 'hours').format('MM/DD h.30 a')}</p> :
      <p className='hours'>{m.format('MM/DD h.00 a')}</p>
  }

  renderWhole(m, isHalf) {
    return isHalf ?
      <p className='hours'>{m.format('MM/DD h.00 a')}</p> :
      <p className='hours'>{m.subtract(1, 'hours').format('MM/DD h.30 a')}</p>
  }

  renderDetail(i, m, isHalf) {
    if (i === 0) {
      return isHalf ?
        <p className='hours'>{m.format('MM/DD h.30 a')}</p> :
        <p className='hours'>{m.format('MM/DD h.00 a')}</p>
    }

    return (i % 2 === 0) ? this.renderHalf(m, isHalf) : this.renderWhole(m, isHalf)
  }

  toggleShowDetailPage({ gameId, gameName, type }) {
    this.setState({ selectedDetail: { gameName, type } })
    const itemsDetail = [];
    const now = this.getIndex();
    const game = this.state.gamesTip.find((game) => game.gameId === gameId);
    const rates = game[type];
    const m = moment(this.state.today);
    const isHalf = m.format('mm') > 30;
    for (let i = 0; i < 8; i++) {
      const rateIndex = this.getCorrectionHourIndex(now, i);
      const lastHourRateIndex = this.getCorrectionHourIndex(now, i + 1);
      const currentRate = rates[rateIndex];
      const lastHourRate = rates[lastHourRateIndex];
      itemsDetail.push(
        <div key={i} className='row-detail'>

          {this.renderDetail(i, m, isHalf)}

          {
            lastHourRate > currentRate ?
              <p className='percentage-down'>{currentRate.toFixed(2)}%</p> :
              <p className='percentage-up'>{currentRate.toFixed(2)}%</p>
          }
        </div>
      )
    }
    this.setState({ showDetail: true, itemsDetail })
  }

  toggleHideDetailPage() {
    this.setState({ showDetail: false })
  }

  showFadeAnimation() {
    return `${this.state.isDisabled ? '' : 'fade-animation'}`
  }

  renderPercentage(rates, gameId, gameName, type) {
    const hours = this.state.today.getHours();
    const lastHourIndex = this.getCorrectionHourIndex(hours, 1);

    return rates[lastHourIndex] > rates[hours] ?
      (<td className={`${this.showFadeAnimation()} percentage percentage-down`} onClick={this.toggleShowDetailPage.bind(this, { gameId, gameName, type })} > {rates[hours].toFixed(2)}%</td>) :
      (<td className={`${this.showFadeAnimation()} percentage percentage-up`} onClick={this.toggleShowDetailPage.bind(this, { gameId, gameName, type })}> {rates[hours].toFixed(2)}%</td>)
  }

  renderGameTips() {
    const hours = this.getIndex()
    return (
      <div className='branded'>
        <table>
          <thead>
            <tr>
              {GAMES_COLUMN.map((colum, index) => (
                <th key={index} className='text-center'>
                  <FormattedMessage id={colum.field} />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {
              this.state.gamesTip.map((game) => (
                <tr key={game.gameId}>
                  <td>
                    <div>
                      <Image className='icon-wrapper vertical-middle' src={game.iconLink} />
                      <span className='game-text vertical-middle'>{game.gameName}</span>
                    </div>
                  </td>
                  <td className={`${this.showFadeAnimation()} text-center>`}> {game.onlineUser[hours]}</td>
                  {this.renderPercentage(game.jackpotRate, game.gameId, game.gameName, TYPE_JACKPOT)}
                  {this.renderPercentage(game.freeGameRate, game.gameId, game.gameName, TYPE_FREE_GAME)}
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    )
  }

  renderTipList() {
    return (
      <div className='branded'>
        {this.state.itemsDetail}
      </div>
    )
  }

  render() {
    return (
      <div className='component-real-time-board'>
        <PageHeader>
          <ToggleGroup branded
            selected={this.state.filterBy}
            onClick={() => this.toggleHideDetailPage()}
            onChange={(data) => this.handleFilterChange(data)}>
            <Tab value={TAB_DEPOSIT}><FormattedMessage id='component.table.realtimeboard.tab.deposit' /></Tab>
            <Tab value={TAB_WITHDRAWAL}><FormattedMessage id='component.table.realtimeboard.tab.withdrawal' /></Tab>
            <Tab value={TAB_GAMES}><FormattedMessage id='component.table.realtimeboard.tab.games' /></Tab>
          </ToggleGroup>
        </PageHeader>
        <OurValue />

        {
          this.state.showDetail ?
            <div>
              <H2 className='header'>{this.state.selectedDetail.gameName}</H2>
              <H3 className='header warn'>{this.state.tab[this.state.selectedDetail.type]}</H3>

              <P className='announce'>(<FormattedMessage id='component.table.realtimeboard.tab.tip.refresh' />)</P>
              {this.renderTipList()}
            </div> :
            <div>
              <H2 className='header'><FormattedMessage id='component.table.realtimeboard.tab.sub.title' /> {this.state.tab[this.state.filterBy]}</H2>
              {
                this.state.filterBy === TAB_GAMES ?
                  <P className='description'><FormattedMessage id='component.table.realtimeboard.tab.tip.table.notice' /> </P> : ''
              }

              {this.renderTableContent()}
            </div>
        }

      </div>
    )
  }
}

export default connect(
  ({ announcement }) => ({
    announcements: announcement.list,
    announcementsCount: announcement.listCount,
    isConnecting: announcement.isConnecting,
    games: announcement.games,
  }),
  dispatch => bindActionCreators({
    listTransactionAnnouncement,
    listGamesTip
  }, dispatch)
)(RealTimeBoard)
