export const GAMES_COLUMN = [
  {
    field: 'component.table.realtimeboard.tab.tip.table.column.1',
  },
  {
    field: 'component.table.realtimeboard.tab.tip.table.column.2',
  },
  {
    field: 'component.table.realtimeboard.tab.tip.table.column.3',
  },
  {
    field: 'component.table.realtimeboard.tab.tip.table.column.4',
  }
]
