import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl';
import { H3, ModalHeader, ModalBody, ModalFooter, Button } from '@framework'

class ErrorModal extends Component {
  render () {
    const { modal, error } = this.props
    return (
      <div>
        <ModalHeader color='brand-secondary'>
          <H3>Hmmm... Something went wrong
            <i class="fas fa-wrench"></i>
          </H3>
          
        </ModalHeader>
        <ModalBody>
          {
            error.message.includes('e0')? 
            <FormattedMessage id={error.message} /> : 
            error.message
          }
        </ModalBody>
        <ModalFooter>
          <Button color='brand-secondary' onClick={() => modal.hide()}>Ok</Button>
        </ModalFooter>
      </div>
    )
  }
}

function mapStatetoProps ({ app }) {
  return {
    error: app.error.response.data,
  }
}

export default connect(mapStatetoProps)(ErrorModal)
