import * as contentful from 'contentful'
import { CONTENTFUL_SPACEID, CONTENTFUL_ACCESS_TOKEN } from '@configs/app.config';

const client = contentful.createClient({
  space: CONTENTFUL_SPACEID,
  accessToken: CONTENTFUL_ACCESS_TOKEN
})

export async function listPromotions({ locale }) {
  const { items } = await client.getEntries({ content_type: 'promotion', locale  })
  return items.map(({ fields }) => ({
    title: fields.title,
    subtitle: fields.subtitle,
    content: fields.content,
    headerImage: fields.headerImage.fields.file.url
  }))
}

export async function listCarouselImages() {
  const { items } = await client.getEntries({ content_type: 'carouselImage'})

  return items.map(({ fields }) => ( fields.image.fields.file.url ))
}