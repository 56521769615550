import Cookies from 'js-cookie'

import { history } from '@store';
import * as authService from '../services/auth.api'
import { SET_AUTH_FLOW, SET_USER, USER_LOGOUT } from '../reducers/auth.reducer'
import { parseJwtPayload } from '@helpers/parse-jwt-payload'
import { getAccountInfo } from './wallet.action'
import { MSG_MODAL } from '@components/ModalWrapper'
import { openModal } from '../actions/app.action'

export function requestOtp ({ phoneNumber, resend = false }) {
  return async dispatch => {
    const resp = await authService.sendOtp({ phoneNumber, isResend: resend })
    const { isRegistered, hasOTP } = resp

    dispatch({
      type: SET_AUTH_FLOW,
      payload: { 
        phoneNumber,
        isRegistered,
        hasOTP
      }
    })

    const bundleMsg = {
      header: 'component.modal.otp.header',
      message: hasOTP ? 'component.modal.has.otp.msg': 'component.modal.otp.msg',
      closeButtonText: 'Ok'
    }
    dispatch(openModal({ modal: MSG_MODAL, bundleMsg }))
    if(!resend) history.push('/auth/verify')
  }
}

export function verifyOtp ({ otp, referralPhoneNumber }) {
  return async (dispatch, getState) => {
    const { phoneNumber, isRegistered } = getState().auth
    const credentials = { phoneNumber, otp, referralPhoneNumber }

    if (isRegistered) {
      await authService.login(credentials)
    } else {
      await authService.verifySignUp(credentials)
    }

    const idToken = Cookies.get('ops_id_token')
    const decoded = parseJwtPayload(idToken)

    dispatch({
      type: SET_USER,
      payload: { ...decoded }
    })

    dispatch(getAccountInfo())

    history.push('/')
  }
}

export function updateProfile ({ birthdate, familyName, givenName }) {
  return async dispatch => {
    await authService.updateProfile({ birthdate, familyName, givenName })
  }
}

export function logout () {
  return async dispatch => {
    await authService.logout();
    history.push('/')
  
    dispatch({
      type: USER_LOGOUT
    })
  }
}
