import { bindActionCreators } from 'redux'
import { NONE } from '../store/reducers/announcement.reducer';

export const socketActionTypes = {
  SOCKET_OPEN: 'SOCKET_OPEN',
  SOCKET_CLOSE: 'SOCKET_CLOSE',
  SOCKET_ERROR: 'SOCKET_ERROR',
  SOCKET_MESSAGE: 'SOCKET_MESSAGE',
  SOCKET_CONNECT: 'SOCKET_CONNECT'
}

export const socketActionCreators = {
  socketOpen: e => ({ type: socketActionTypes.SOCKET_OPEN }),
  socketClose: e => ({ type: socketActionTypes.SOCKET_CLOSE }),
  socketError: err => ({ type: socketActionTypes.SOCKET_ERROR, payload: err }),
  socketMessage: e => {
    if (e && e.data && JSON.parse(e.data).message !== 'Forbidden') {
      const data = JSON.parse(e.data);

      switch(data.type) {
        case 'keepalive':
          return({
            type: NONE
          })

        default:
          return ({
            type: socketActionTypes.SOCKET_MESSAGE,
            payload: { 
              data: {
                ...JSON.parse(e.data),
                createdAt: new Date().toISOString()
              }
            }
          })
      }
      
    }
  },
  socketConnect: e => ({ type: socketActionTypes.SOCKET_CONNECT })
}

export const createSocketMiddleware = ({
  socketURL,
  subscribeData,
  shouldInstantiate,
  eventHandlers
}) => store => next => action => {
  if (shouldInstantiate(action)) {
    const ws = new WebSocket(socketURL)
    const boundEventHandlers = bindActionCreators(eventHandlers, store.dispatch)

    ws.onopen = e => {
      boundEventHandlers.onopen(e)
      ws.send(JSON.stringify({ action: 'keepalive' }))
      setInterval(() => { ws.send(JSON.stringify({ action: 'keepalive' })); }, 30000)
    }

    ws.onclose = boundEventHandlers.onclose
    ws.onerror = boundEventHandlers.onerror
    ws.onmessage = boundEventHandlers.onmessage
  } else {
    return next(action)
  }
}