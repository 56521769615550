import React from 'react'
import { FormattedMessage } from 'react-intl'
import { appInterceptedAxios ,authInterceptedAxios } from './interceptors'

export function addBank ({ bankCode, account, name }) {
  return authInterceptedAxios({
    method: 'put',
    url: '/agent/user-add-bank',
    data: {
      bankCode,
      bankAccountNumber: account,
      bankAccountName: name
    },
    loadKey: <FormattedMessage id='api.agent.loader.addBank' />,
    isInterruptive: true
  })
}

export function getAccountInfo () {
  return authInterceptedAxios({
    method: 'get',
    url: '/agent/user-get-account-info',
    loadKey: <FormattedMessage id='api.agent.loader.getAccountInfo' />,
    isInterruptive: true
  })
}

export function listTransactions ({ nextKey }) {
  return authInterceptedAxios({
    method: 'get',
    url: '/agent/user-list-transactions',
    params: {
      nextKey,
      limit: 10,
      isAsc: false
    },
    loadKey: <FormattedMessage id='api.agent.loader.listTransactions' />
  })
}

export function syncScore () {
  return authInterceptedAxios({
    method: 'post',
    url: '/agent/user-sync-score',
    loadKey: <FormattedMessage id='api.agent.loader.syncScore' />,
    isInterruptive: true
  })
}

export function resetPassword ({ password }) {
  return authInterceptedAxios({
    method: 'post',
    data: {
      password
    },
    url: '/agent/reset-password',
    loadKey: <FormattedMessage id='api.agent.loader.resetPassword' />,
    isInterruptive: true
  })
}

export function listPublicTransactions () {
  return appInterceptedAxios({
    method: 'get',
    url: '/agent/list-public-transactions',
    loadKey: <FormattedMessage id='api.agent.loader.listPublicTransactions' />,
    isInterruptive: true
  })
}

export function listBonusType () {
  return authInterceptedAxios({
    method: 'get',
    url: '/agent-user/user-list-bonus-type',
    loadKey: <FormattedMessage id='api.agent.loader.listBonusType' />,
    isInterruptive: true
  })
}

export function withdrawBonus ({ id, bankCode, bankAccountNumber, bankAccountName  }) {
  return authInterceptedAxios({
    method: 'post',
    url: '/agent-user/user-withdraw-bonus',
    data: {
      id,
      bankCode,
      bankAccountNumber,
      bankAccountName
    },
    loadKey: <FormattedMessage id='api.agent.loader.withdrawBonusType' />,
    isInterruptive: true
  })
}

export function claimBonus ({ id }) {
  return authInterceptedAxios({
    method: 'post',
    url: '/agent-user/user-claim-bonus',
    data: { id },
    loadKey: <FormattedMessage id='api.agent.loader.claimBonusType' />,
    isInterruptive: true
  })
}