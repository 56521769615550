import React, { Component } from 'react'
import { H3, P, ModalHeader, ModalBody, ModalFooter, Button, Flex, Image } from '@framework'

import './Maintenance.modal.scss'


class MaintenanceModal extends Component {
  render () {
    const { modal } = this.props
    return (
      <div>
        <ModalHeader color='brand-primary'>
          <H3>Dear Member Spins2win</H3>
        </ModalHeader>
        <ModalBody>
          {/* <P>Our system is undermaintenance, Please Contact Live Chat or Official WhatsApp to made Deposit. Thank You</P> */}
          <P>Sistem kami dalam penyelenggaraan, sila hubungi WhatsApp rasmi untuk membuat deposit. Terima kasih</P>
          
          <H3>
            <Flex className='__center_text'>
              <Image className='__icon' src={`/icons/whatapps.png`} />
              WhatsApp: <a href='https://Spins2WinFBADS.wasap.my'>Please Click Me</a>
            </Flex>
          </H3>

          <H3>
            <Flex className='__center_text'>
              <Image className='__icon' src={`/icons/telegram.svg`}  />
              Telegram: <a href='https://t.me/spins2win'>Please Click Me</a>
            </Flex>
          </H3>

          <H3>7/24 Online</H3>
        </ModalBody>
        <ModalFooter>
          <Button color='success' target='_blank' onClick={() => modal.hide()}>Ok</Button>
        </ModalFooter>
      </div>
    )
  }
}

export default MaintenanceModal
