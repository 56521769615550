import React from 'react'
import { FormattedMessage } from 'react-intl'
import { appInterceptedAxios } from './interceptors'

export function getAnnouncement () {
  return appInterceptedAxios({
    method: 'get',
    url: '/messaging/get-public-announcement',
    loadKey: <FormattedMessage id='api.messaging.loader.getAnnouncement' />,
    isInterruptive: true
  })
}

export function getDownloadLink () {
  return appInterceptedAxios({
    method: 'get',
    url: '/messaging/get-public-downloadlink',
    isInterruptive: false
  })
}