import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Page } from '@framework'
import { Blog } from '@components'
import { Helmet } from 'react-helmet'

import { listPromotions } from '@store/actions/promotion.action'

class Promotion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      posts: []
    }
  }

  componentDidMount() {
    const { locale: { language } } = this.props
    this.props.listPromotions({ locale: language.locale });
  }

  render() {
    const { posts } = this.props

    return (
      <Page className='scope-promotion'>
        <Blog posts={posts} type='promotion' />
        <Helmet title='Spins2win: Promotion' />
        
      </Page>
    )
  }
}

export default connect(
  ({ promotion: { posts }, locale }) => ({
    posts,
    locale
  }),
  dispatch => bindActionCreators({ listPromotions }, dispatch)
)(Promotion)