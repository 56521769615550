import * as agentService from '../services/agent.api'
import * as messagingService from '../services/messaging.api'
import * as gameService from '../services/game.api'

import {
  SET_ANNOUCEMENTS,
  SET_BROADCAST_MESSAGE,
  SET_IS_CONNECTING,
  SET_DOWNLOAD_LINK,
  SET_GAME_TIP
} from '../reducers/announcement.reducer'
import { socketActionCreators } from '../sockets';

export function listTransactionAnnouncement () {
  return async (dispatch, getState) => {

    dispatch({
      type: SET_IS_CONNECTING
    })

   
    const { data } = await agentService.listPublicTransactions()
   
    dispatch({
      type: SET_ANNOUCEMENTS,
      payload: {
        announcements: data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).filter(({ remark }) => remark === 'deposit' || remark ==='withdrawal' )
      }
    })

    const isConnect = getState().announcement.isConnectSocket;

    if(!isConnect) {
      dispatch(socketActionCreators.socketConnect())
    }

  }
}

export function listGamesTip () {
  return async dispatch => {
    const { data } = await gameService.listGamesTip()
    dispatch({
      type: SET_GAME_TIP,
      payload: {
        games: data.games
      }
    })

  }
}

export function getAnnouncement () {
  return async dispatch => {
    const { data } = await messagingService.getAnnouncement()

    dispatch({
      type: SET_BROADCAST_MESSAGE,
      payload: {
        broadcast: data.message
      }
    })
  }
}

export function getDownloadLink() {
  return async dispatch => {
    const { data } = await messagingService.getDownloadLink()

    dispatch({
      type: SET_DOWNLOAD_LINK,
      payload: {
        downloadLink: data.downloadLink
      }
    })
  }
}