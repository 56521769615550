import React, { Component } from 'react'
import { Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { workspaceRoutes } from '@routes'
import { renderRoutes } from '@helpers/routes'


import { logout } from '@store/actions/auth.action'
import { getAccountInfo } from '@store/actions/wallet.action'

import './Workspace.scss'

class Workspace extends Component {
  componentDidMount () {
    const {
      userId,
      getAccountInfo
    } = this.props

    if (!userId) {
      getAccountInfo()
    }
  }

  render () {
    return (
      <div className='scope-workspace'>
        <Switch>
          {renderRoutes(workspaceRoutes)}
        </Switch>
      </div>
    )
  }
}

export default connect(
  ({ wallet }) => ({
    userId: wallet.cognitoId,
    phoneNumber: wallet.phoneNumber
  }), 
  dispatch => bindActionCreators({
    logout,
    getAccountInfo
  }, dispatch)
)(Workspace)