import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { FormattedMessage } from 'react-intl';

import { Page, PageHeader,  PageFooter, Breadcrumb, Crumb, ToggleGroup, Tab, P } from '@framework'

import WithdrawalPage from './Withdrawal'
import { BindBankForm } from '@components/Forms'

import './Overview.scss'

const TAB_WITHDRAWAL = 'tab-withdrawal'
const TAB_BIND_BANK = 'tab-bind-bank'

export default class WithdrawalOverviewPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      page: TAB_WITHDRAWAL
    }
  }

  handleToggle (selected) {
    this.setState({
      page: selected
    })
  }

  renderSelectedPage () {
    const { page } = this.state
    switch (page) {
      case TAB_WITHDRAWAL:
        return <WithdrawalPage />
      case TAB_BIND_BANK:
        return <BindBankForm />
      default:
        return null
    }
  }

  render () {
    return (
      <Page paperWhite className='page-withdrawal'>
        <PageHeader>
          <ToggleGroup branded
            selected={this.state.page}
            onChange={(selected) => this.handleToggle(selected)}>
            <Tab value={TAB_WITHDRAWAL}>
              <FormattedMessage id='page.withdrawal.overview.tab.withdraw' />
            </Tab>
            <Tab value={TAB_BIND_BANK}>
              <FormattedMessage id='page.withdrawal.overview.tab.bindbank' />
            </Tab>
          </ToggleGroup>
        </PageHeader>
        <Helmet title='Spins2win: Withdrawal' />
        <Breadcrumb>
          <Crumb to='/'>Home</Crumb>
          <Crumb>Withdrawal</Crumb>
        </Breadcrumb>
        {this.renderSelectedPage()}
        <PageFooter className='footer'>
          <P><FormattedMessage id='page.withdrawal.overview.text.minimum' />: MYR 50</P>
          <P><FormattedMessage id='page.withdrawal.overview.text.maximum' />: MYR 50,000</P>
        </PageFooter>
    </Page>
    )
  }
}

