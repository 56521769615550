import moment from 'moment'
import _find from 'lodash.find'
import { history } from '@store'
import { BANKS } from '@constants/banks'
import * as paymentService from '../services/payment.api'
import * as agentService from '../services/agent.api'

import {
  SET_TRANSACTIONS,
  APPEND_TRANSACTIONS,
  SET_WITHDRAWAL_REQUESTS,
  SET_SCORE,
  SET_ACCOUNT,
  SET_BONUSES,
  SPEND_GAME_POINT,
  SET_GAME_PRIZE,
  SAVE_DEPOSIT_FORM,
  CLEAR_DEPOSIT_FORM,
  SET_BONUS_TYPE,
  REMOVE_BONUS_TYPE,
  WITHDRAWAL
} from '../reducers/wallet.reducer'
import { openModal } from '../actions/app.action'
import { RESET_PASSWORD_SUCCESS_MODAL, BIRTHDATE_MODAL } from '@components/ModalWrapper'

export function getAccountInfo () {
  return async dispatch => {

    const account = await agentService.getAccountInfo()
    dispatch({
      type: SET_ACCOUNT,
      payload: {
        ...account
      }
    })

    if (!account.birthdateCode && account.playerUsername) {
      dispatch(openModal({
        modal: BIRTHDATE_MODAL,
        config: {
          nonDismissable: true
        }
      }))
    }

    dispatch({
      type: SET_ACCOUNT,
      payload: {
        ...account,
      }
    })
  }
}

export function deposit ({ score, bankCode, bonus, token }) {
  return async dispatch => {
    const { concateLink } = await paymentService.deposit({ score, bankCode, bonus, token })
    window.location = concateLink
    dispatch(clearDepositForm())
  }
}

export function depositCash ({ score, bank, token, depositedDate, referenceId, imageLink }) {
  return async dispatch => {
    await paymentService.depositCash({ score, bank, token, depositedDate, referenceId, imageLink });
    history.push('/')
    dispatch(clearDepositForm())
  }
}

export function requestWithdrawal({ score, bankCode, bankAccountNumber, bankAccountName }) {
  return async dispatch => {
    await paymentService.requestWithdrawal({ score, bankCode, bankAccountNumber, bankAccountName })

    dispatch({
      type: WITHDRAWAL,
      payload: { score }
    })
    history.push('/')
  }
}

export function listTransactions ({ startDate, endDate }) {
  return async dispatch => {
    const { data, nextKey } = await paymentService.listOrders({ startDate, endDate })
    dispatch({
      type: SET_TRANSACTIONS,
      payload: {
        transactions: data,
        nextKey
      }
    })
  }
}

export function listMoreTransactions ({ startDate, endDate }) {
  return async (dispatch, getState) => {
    const {
      transactionsNextKey: stateNextKey
    } = getState().wallet

    const { data, nextKey } = await paymentService.listOrders({ startDate, endDate, nextKey: stateNextKey })

    dispatch({
      type: APPEND_TRANSACTIONS,
      payload: {
        transactions: data,
        nextKey
      }
    })
  }
}

export function syncScore () {
  return async dispatch => {
    const [score] = await Promise.all([
      agentService.syncScore(),
    ])

    dispatch({
      type: SET_SCORE,
      payload: {
        ...score,
      }
    })

    const { data } = await paymentService.listBonuses();
    
    dispatch({
      type: SET_BONUSES,
      payload: {
        bonuses: data
      }
    })
  }
}

export function addBank ({ bankCode, account, name }) {
  return async dispatch => {
    await agentService.addBank({ bankCode, account, name })
    dispatch(getAccountInfo())
    history.push('/manage/wallet/withdrawal')
  }
}

export function listWithdrawalRequests () {
  return async dispatch => {
    const { data } = await paymentService.listWithdrawalRequest({
      startDate: moment().startOf('month').toISOString(),
      endDate: moment().endOf('month').toISOString()
    })
    dispatch({
      type: SET_WITHDRAWAL_REQUESTS,
      payload: {
        withdrawalRequests: data
      }
    })
    
  }
}

export function resetPassword ({ password }) {
  return async dispatch => {
    await agentService.resetPassword({ password })
    dispatch(openModal({ modal: RESET_PASSWORD_SUCCESS_MODAL }))
    history.push('/')
  }
}

export function listBonuses () {
  return async dispatch => {
    const { data } = await paymentService.listBonuses();
    
    dispatch({
      type: SET_BONUSES,
      payload: {
        bonuses: data
      }
    })
  }
}

export function spendGamePoint ({ pointSpent }) {
  return dispatch => {
    dispatch({
      type: SPEND_GAME_POINT,
      payload: { pointSpent }
    })
  }
}

export function setGamePrize ({ winScore = 0, winPoint = 0 }) {
  return dispatch => {
    dispatch({
      type: SET_GAME_PRIZE,
      payload: { winScore, winPoint }
    })
  }
}


export function saveDepositForm ({ bankCode, score, token }) {
  return dispatch => {
    const { label: bank } = _find(BANKS, bank => bank.code === bankCode)

    dispatch({
      type: SAVE_DEPOSIT_FORM,
      payload: { bank, score, token }
    })
  }
}

export function clearDepositForm () {
  return dispatch => {
    dispatch({
      type: CLEAR_DEPOSIT_FORM
    })
  }
}

export function listBonusType () {
  return async dispatch => {
    const { data } = await agentService.listBonusType();
    dispatch({
      type: SET_BONUS_TYPE,
      payload: { bonusTypes: data }
    })
  }
}

export function requestClaimBonus({ id }) {
  return async dispatch => {
    await agentService.claimBonus({ id })
    dispatch({
      type: REMOVE_BONUS_TYPE,
      payload: { id }
    })
  }
}

export function requestWithdrawBonus({ id, bankCode, bankAccountNumber, bankAccountName }) {
  return async dispatch => {
    await agentService.withdrawBonus({ id, bankCode, bankAccountNumber, bankAccountName })
    dispatch({
      type: REMOVE_BONUS_TYPE,
      payload: { id }
    })
  }
}
