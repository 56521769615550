import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { Switch } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { StyleRoot } from 'radium'
import { FormattedMessage } from 'react-intl';

import { renderRoutes } from '@helpers/routes'
import { appRoutes } from '@routes'
import { AuthPrompt, InterruptiveLoadWrapper, LoadWrapper, ModalWrapper } from '@components'
import { Navbar, NavbarItem, Edgehanger } from '@framework'

import '@fortawesome/fontawesome-free/css/all.min.css'
import './App.scss'

class App extends Component {

  render () {
    return (
      <StyleRoot>
        <div className='scope-app'>
          <ModalWrapper />
          <LoadWrapper />
          <InterruptiveLoadWrapper />
          <Helmet title='Spins2win' />
          <AuthPrompt />
          <Switch>
            {renderRoutes(appRoutes)}
          </Switch>
          {/* <LiveChatButton /> */}
          <Edgehanger position='bottom'>
            <Navbar>
              <NavbarItem icon='Deposit' showSelected={/deposit/}
                to='/manage/wallet/deposit'><FormattedMessage id='page.app.tab.deposit' /></NavbarItem>
              <NavbarItem icon='Withdrawal' showSelected={/withdrawal/}
                to='/manage/wallet/withdrawal'><FormattedMessage id='page.app.tab.withdraw' /></NavbarItem>
              <NavbarItem icon='Wheel' showSelected={/wheel/}
                to='/manage/game/wheel'><FormattedMessage id='page.app.tab.wheel' /></NavbarItem>
              <NavbarItem icon='Bonus' showSelected={/bonus/}
                to='/manage/game/bonus'><FormattedMessage id='page.app.tab.promotion' /></NavbarItem>
              <NavbarItem icon='Profile' showSelected={/profile/} showBadge={this.props.bonus.length > 0}
                to='/manage/profile'><FormattedMessage id='page.app.tab.profile' />
                </NavbarItem>
            </Navbar>
          </Edgehanger>
          <div className='transparent'>
            <Navbar>
              <NavbarItem icon='Deposit' showSelected={/deposit/}
                to='/manage/wallet/deposit'>Deposit</NavbarItem>
              <NavbarItem icon='Withdrawal' showSelected={/withdrawal/}
                to='/manage/wallet/withdrawal'>Withdraw</NavbarItem>
              <NavbarItem icon='Wheel' showSelected={/wheel/}
                to='/manage/game/wheel'>Wheel</NavbarItem>
              <NavbarItem icon='Bonus' showSelected={/bonus/}
                to='/manage/game/bonus'>Promotion</NavbarItem>
              <NavbarItem icon='Profile' showSelected={/profile/}
                to='/manage/profile'>Profile</NavbarItem>
            </Navbar>
          </div>
        </div>
      </StyleRoot>
    )
  }
}

// withRouter is needed
// export default withRouter(App)

export default compose(
  withRouter,
  connect(
    ({ wallet }) => ({
      bonus: wallet.bonusTypes
    }),
    dispatch => bindActionCreators({
    }, dispatch)
  )
)(App);