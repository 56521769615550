import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl';

import { H3, ModalHeader, ModalBody, ModalFooter, Form, Submit, Input, Flex, Image, Button } from '@framework'
import { updateProfile } from '@store/actions/auth.action'
import { updateLanguage } from '@store/actions/locale.action'
import { MALAY, ENGLISH } from '@constants/locale';

import './Language.modal.scss'

const INPUT_LANGUAGE = 'language-selected';

class LanguageModal extends Component {
  constructor (props) {
    super(props)
    const { language } = this.props;
    this.state = {
      [INPUT_LANGUAGE]: language
    }
  }

  async handleSubmit () {
    const { modal, updateLanguage } = this.props
    modal.hide()
    updateLanguage(this.state[INPUT_LANGUAGE]);
  }

  handleInputChange (state, event) {
    this.setState({ [state]: event.target.value })
  }

  render () {
    return (
      <Form onSubmit={this.handleSubmit.bind(this)}>
        <ModalHeader color='brand-primary'>
          <H3><FormattedMessage id='component.modal.language.header' /></H3>
        </ModalHeader>
        <ModalBody>
           <Flex>
              <Image className='flag' src='/flags/US.png'/>
              <Input label='English' type='radio' value={ENGLISH} name='language'
                checked={this.state[INPUT_LANGUAGE] === ENGLISH} 
                onChange={this.handleInputChange.bind(this, INPUT_LANGUAGE)}/>
            </Flex>
            <Flex>
              <Image className='flag' src='/flags/Malaysia.png'/>
              <Input label='Malay' type='radio' value={MALAY} name='language'
                checked={this.state[INPUT_LANGUAGE] === MALAY} 
                onChange={this.handleInputChange.bind(this, INPUT_LANGUAGE)}/>
            </Flex>
        </ModalBody>
        <ModalFooter>
          <Submit color='brand-success'>
            <FormattedMessage id='common.input.submit' />
          </Submit>
          <Button color='brand-secondary-rounded' onClick={() => this.props.modal.hide()}>
            <FormattedMessage id='common.input.cancel' />
          </Button>
        </ModalFooter>
      </Form>
    )
  }
}

export default connect(
  ({ locale }) => ({
    language: locale.language.locale
  }),
  dispatch => bindActionCreators({
    updateProfile,
    updateLanguage
  }, dispatch)
)(LanguageModal)
