import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import { openModal } from "@store/actions/app.action";
import { MAINTENANCE_MODAL } from "@components/ModalWrapper";
import { requestOtp } from "@store/actions/auth.action";
import { Page, Form, Submit, H1, Input } from "@framework";

import "./Login.scss";

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: "",
    };
  }

  handleInputChange(state, event) {
    this.setState({ [state]: event.target.value });
  }

  openMaintenanceModal() {
    this.props.openModal({ modal: MAINTENANCE_MODAL });
  }

  render() {
    return (
      <div className="page-login">
        <Helmet title="Spins2win: Register / Login" />
        <Page paperWhite>
          <H1 className="title">
            <FormattedMessage id="page.login.body.title" />
          </H1>
          <div className="page-decorator" />
          <Form onSubmit={() => this.openMaintenanceModal()}>
            {/* <Form onSubmit={() => this.props.requestOtp(this.state)}> */}
            <Input
              label={<FormattedMessage id="page.login.input.phone" />}
              autoFocus
              onChange={this.handleInputChange.bind(this, "phoneNumber")}
              placeholder="eg. 0123456789"
            />
            <Submit color="brand-primary">
              <FormattedMessage id="page.login.input.submit" />
            </Submit>
          </Form>
        </Page>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ requestOtp, openModal }, dispatch);
}

export default connect(null, mapDispatchToProps)(LoginPage);
