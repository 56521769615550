import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl';

import { Form, Input, Select, Submit, Tabular } from '@framework'

import { BANKS } from '@constants/banks'
import { addBank } from '@store/actions/wallet.action'

const INPUT_BANK = 'bankCode'
const INPUT_ACCOUNT_NUMBER = 'account'
const INPUT_ACCOUNT_NAME = 'name'

class BindBankForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      [INPUT_BANK]: 0,
      [INPUT_ACCOUNT_NUMBER]: 0,
      [INPUT_ACCOUNT_NAME]: '',
    }
  }

  handleInputChange (state, event) {
    this.setState({ [state]: event.target.value })
  }

  handleSubmit() {
    if(this.state[INPUT_BANK] && this.state[INPUT_BANK] !== 0) {
      this.props.addBank(this.state)
    }
  }

  render () {
    const { banks } = this.props

    return (
      <div>
        <Form onSubmit={() => this.handleSubmit()}>
          <Select label={<FormattedMessage id='component.form.bindbank.input.bankname' />}
            value={this.state.value} onChange={this.handleInputChange.bind(this, INPUT_BANK)}>
            {BANKS.map(({code, label}) => (<option key={code} value={code}>{label}</option>))}
          </Select>
          <Input label={<FormattedMessage id='component.form.bindbank.input.accountnumber' />}
            onChange={this.handleInputChange.bind(this, INPUT_ACCOUNT_NUMBER)} />
          <Input label={<FormattedMessage id='component.form.bindbank.input.accountname' />}
            onChange={this.handleInputChange.bind(this, INPUT_ACCOUNT_NAME)} />
          <Submit color='brand-success'>
            <FormattedMessage id='component.form.bindbank.input.submit' />
          </Submit>
        </Form>
        <Tabular data={banks}
          staticColumns={[
            {
              headerAccessor: "bankAccountName",
              subheaderAccessor: "bankAccountNumber"
            }
          ]}
          dynamicColumns={[
            {
              Header: "Bank",
              accessor: "bankCode"
            }
          ]} />
      </div>
    )
  }
}

export default connect(
  ({ wallet }) => ({
    banks: wallet.banks
  }), 
  dispatch => bindActionCreators({
    addBank
  }, dispatch)
)(BindBankForm)
