import * as gameService from '../services/game.api'
import { SET_GAMES, SET_WON_ITEM } from '../reducers/game.reducer'

export function listGames () {
  return async dispatch => {
    const { data } = await gameService.listGames()

    dispatch({
      type: SET_GAMES,
      payload: { games: data }
    })
  }
}

export function setWonGame ({ itemId, gameId }) {
  return async dispatch => {
    dispatch({
      type: SET_WON_ITEM,
      payload: { itemId, gameId }
    })
  }
}