import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl';

import { Flex, Select, Submit, H3 } from '@framework'
import { requestClaimBonus, requestWithdrawBonus , listBonusType } from '@store/actions/wallet.action'
import './Bonus.scss'

const INPUT_BANK = 'bank'

class BonusPage extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  handleBankChange (state, event) {
    this.setState({
      ...JSON.parse(event.target.value)
    })
  }

  renderBonusType(id, key, bonus) {
    const { addedBanks } = this.props;
    return (
      <Flex key={id} column className='border-line'>
        <H3> <FormattedMessage id={`page.profile.bonus.label.${key}`} />: {bonus} </H3>
        <Select value={this.state.value} onChange={this.handleBankChange.bind(this, INPUT_BANK)}>
          <option>- Select an account -</option>
          {addedBanks.map(bank => 
            (<option key={bank.bankCode+bank.bankAccountNumber}
              value={JSON.stringify(bank)}>
              {bank.bankCode} | {bank.bankAccountNumber} | {bank.bankAccountName}
            </option>))}
        </Select>
        <Flex>
          <Submit color='brand-success' onClick={() => { this.props.requestClaimBonus({ id })}}>
            <FormattedMessage id='page.profile.bonus.input.claim' />
          </Submit>
          <Submit color='brand-success' onClick={() => { this.props.requestWithdrawBonus({ id, ...this.state })}}>
            <FormattedMessage id='page.profile.bonus.input.withdraw' />
          </Submit>
        
        </Flex>
     
      </Flex>
    )
  }

  render () {
    const { bonus } = this.props;
    return(
      <Flex column>
        {
          bonus.length > 0 ?
          bonus.map((item) => this.renderBonusType(item.id, item.type, item[item.type])) :
          <H3> <FormattedMessage id={`page.profile.bonus.label.empty`} /> </H3>
        }
      </Flex>
    ) 
  }
}

export default connect(
  ({ wallet, locale }) => ({
    addedBanks: wallet.banks,
    language: locale.language,
    bonus: wallet.bonusTypes
  }),
  dispatch => bindActionCreators({
    requestClaimBonus,
    requestWithdrawBonus,
    listBonusType
  }, dispatch)
)(BonusPage)
