import {
  LoginPage,
  VerifyOTPPage
} from '@pages/Auth'
import Error404 from '@pages/Error404'

export const authRoutes = [
  {
    path: '/auth/login',
    component: LoginPage
  },
  {
    path: '/auth/verify',
    component: VerifyOTPPage
  }, {
    component: Error404
  }
]
