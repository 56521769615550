import moment from 'moment'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { authInterceptedAxios } from './interceptors'

export function deposit ({ score, bankCode, token }) {
  return authInterceptedAxios({
    method: 'post',
    url: '/payment/user-deposit',
    data: { 
      score, bankCode,
      token: token ? token : undefined
    },
    loadKey: <FormattedMessage id='api.payment.loader.deposit' />,
    isInterruptive: true
  })
}

export function depositCash ({ score, bank, token, depositedDate, referenceId, imageLink }) {
  return authInterceptedAxios({
    method: 'post',
    url: '/payment/user-cash-deposit-request',
    data: {
      score, bank, depositedDate, referenceId, imageLink,
      token: token ? token : undefined
    },
    loadKey: <FormattedMessage id='api.payment.loader.depositCash' />,
    isInterruptive: true,
    successPrompt: {
      header: 'Deposit Request Success',
      message: 'Please be patience while we process your deposit request.'
    }
  })
}

export function requestWithdrawal ({ score, bankCode, bankAccountNumber, bankAccountName }) {
  return authInterceptedAxios({
    method: 'post',
    url: '/payment/user-withdrawal-request',
    data: {
      score,
      bankCode,
      bankAccountNumber,
      bankAccountName
    },
    loadKey: <FormattedMessage id='api.payment.loader.requestWithdrawal' />,
    isInterruptive: true,
    successPrompt: {
      header: 'Withderawal Request Success',
      message: 'Please be patience while we process your withdraw request.'
    }
  })
}

export function listWithdrawalRequest ({ startDate, endDate }) {
  return authInterceptedAxios({
    method: 'get',
    url: '/payment/user-list-withdrawal-submitted',
    params: {
      limit: 20,
      startDate,
      endDate
    },
    loadKey: <FormattedMessage id='api.payment.loader.listWithdrawalRequest' />
  })
}

export function listBonuses () {
  return authInterceptedAxios({
    method: 'get',
    url: '/payment/user-get-bonus',
    loadKey: <FormattedMessage id='api.payment.loader.listBonuses' />
  })
}

export function listOrders ({ nextKey, startDate, endDate }) {
  return authInterceptedAxios({
    method: 'get',
    url: '/payment/user-list-order',
    params: {
      nextKey,
      limit: 6,
      startDate: moment(startDate).startOf('day').toDate(),
      endDate: moment(endDate).endOf('day').toDate(),
      isAsc: false
    },
    loadKey: <FormattedMessage id='api.payment.loader.listOrders' />
  })
}