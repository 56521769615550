import React from 'react'
import './Edgehanger.scss'

export const Edgehanger = (props) => {
  const {
    children,
    className,
    position,
    ...attr
  } = props

    return (
      <div className={`
        af-edgehanger ${className}
        ${position ? `--${position}` : '--top'}
      `} {...attr}>
        {children}
      </div>
    )
}
