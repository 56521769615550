import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import random from 'lodash.random'
import { FormattedMessage } from 'react-intl';

import { Flex, Image } from '@framework'
import { openModal } from '@store/actions/app.action'
import { spendGamePoint } from '@store/actions/wallet.action'
import { setWonGame } from '@store/actions/game.action'
import { playGame } from '@store/services/game.api'
import { SPIN_SUCCESS_MODAL } from '@components/ModalWrapper'

import './Spinwheel.scss'

const sleep = m => new Promise(r => setTimeout(r, m))
const sound = new Audio('https://s3.amazonaws.com/freecodecamp/simonSound1.mp3');

class SpinWheel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cursorCurrentIndex: 0,
      cursorJumpInterval: 100,
      isSpinning: false,
      hover: false,
      traverseId: ''
    }
  }

  componentDidMount() {
    this.setTraverse();
  }
  
  setTraverse(){
    const traverseId = setInterval(() => { this.stepwheel() }, 1000)
    this.setState({ traverseId });
  }

  stepwheel () {
    const { cursorCurrentIndex } = this.state
    const { gameItems } = this.props.game

    this.setState({
      cursorCurrentIndex: cursorCurrentIndex === gameItems.length -1
      ? 0
      : cursorCurrentIndex + 1
    })
  }

  resetWheel () {
    this.setState({ cursorJumpInterval: 100 })
  }

  manipulateStepSpeed(i, totalSteps) {
    const { spinModifier, cursorJumpInterval } = this.state
    const { gameItems } = this.props.game

    if (i < gameItems.length + spinModifier) {
      this.setState({ cursorJumpInterval: cursorJumpInterval - 8 })
    } else if (i > totalSteps - spinModifier - gameItems.length) {
      this.setState({ cursorJumpInterval: cursorJumpInterval + cursorJumpInterval * 0.19 })
    }
  }

  async spinWheel () {

    const { openModal, setWonGame, spendGamePoint } = this.props
    const { gameItems, token, gameId, pointsToPlay } = this.props.game

    this.setState({ isSpinning: true })
    await this.resetWheel()
    const { itemId } = await playGame({ token })
    const { cursorCurrentIndex, traverseId } = this.state;
    clearInterval(traverseId)
    spendGamePoint({ pointSpent: pointsToPlay })
    setWonGame({ itemId, gameId })

    const itemIndex = gameItems.findIndex(item => item.itemId === itemId)

    const spinModifier = random(7, 9)
    this.setState({ spinModifier })

    const distanceToStopIndex = cursorCurrentIndex > itemIndex 
    ? (gameItems.length - cursorCurrentIndex + itemIndex)
    : itemIndex - cursorCurrentIndex;

    const totalSteps = spinModifier * gameItems.length + distanceToStopIndex;
    for (let i = gameItems.length; i < totalSteps; i++) {
      const { cursorJumpInterval } = this.state
      this.stepwheel()
      this.manipulateStepSpeed(i, totalSteps)
      sound.play();
      await sleep(cursorJumpInterval)
    }
    this.setState({ isSpinning: false })
    this.setTraverse();
    openModal({ modal: SPIN_SUCCESS_MODAL })
  }

  renderItemClassName (isHighlighted) {
    return `item ${isHighlighted ? `--highlight` : ''}`.trim()
  }

  renderSingleSpinItem(index) {
    const { cursorCurrentIndex } = this.state
    const { gameItems } = this.props.game

    return (
      <Flex column className={this.renderItemClassName(cursorCurrentIndex === index)}>
        <Image src={gameItems[index].imageLink} />
      </Flex>
    )
  }

  renderGamePanel() {

    const { isSpinning } = this.state
    const { gamePoint } = this.props
    const { pointsToPlay } = this.props.game
    const INSUFFICIENT_POINT = gamePoint < pointsToPlay

    return(
      <div>
        <div className="image-container">
          <button className='spin-btn '
           disabled={isSpinning || INSUFFICIENT_POINT}
           onClick={this.spinWheel.bind(this)}>
            <img className="spin-logo" src='/spin_button.png' alt=''/>
          </button>
        </div>
        <div className='label-panel'>
          <Flex className='label-container' column>
            <div className='label'>
              <FormattedMessage id='component.spinwheel.balance' />
            </div>
            <span>{gamePoint.toFixed(0)}</span>
          </Flex>
          <Flex className='label-container' column>
            <div className='label'>
             <FormattedMessage id='component.spinwheel.point' />
            </div>
            <span>{pointsToPlay.toFixed(0)}</span>
          </Flex>
        </div>
      </div>
    )
  }

  renderSpinItems () {
    const { gameBackground } = this.props.game
    const gamePanelStyles = { backgroundImage: `url('${gameBackground}')` }

    return (
      <Flex>
        <table>
        <tr>
            <td>{this.renderSingleSpinItem(12)}</td>
            <td>{this.renderSingleSpinItem(13)}</td>
            <td>{this.renderSingleSpinItem(0)}</td>
            <td>{this.renderSingleSpinItem(1)}</td>
            <td>{this.renderSingleSpinItem(2)}</td>
        </tr>
        <tr>
            <td>{this.renderSingleSpinItem(11)}</td>
            <td rowspan="2" colspan="3" style={gamePanelStyles} className='game-panel'>{this.renderGamePanel()}</td>
            <td>{this.renderSingleSpinItem(3)}</td>
        </tr>
        <tr>
            <td>{this.renderSingleSpinItem(10)}</td>
            <td>{this.renderSingleSpinItem(4)}</td>
        </tr>
        <tr>
            <td>{this.renderSingleSpinItem(9)}</td>
            <td>{this.renderSingleSpinItem(8)}</td>
            <td>{this.renderSingleSpinItem(7)}</td>
            <td>{this.renderSingleSpinItem(6)}</td>
            <td>{this.renderSingleSpinItem(5)}</td>
        </tr>
      </table>


    </Flex>
    )
  }

  render () {
    return (
      <div className='component-spinwheel'>
        {this.renderSpinItems()}
      </div> 
    )
  }
}

export default connect(
  ({ wallet }) => ({
    gamePoint: wallet.gamePoint,
    lastUpdated: wallet.lastUpdatedScore
  }),
  dispatch => bindActionCreators({
    openModal,
    setWonGame,
    spendGamePoint
  }, dispatch)
)(SpinWheel)
