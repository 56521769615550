import { INIT } from './app.reducer'
import { socketActionTypes } from '../sockets'

export const SET_ANNOUCEMENTS = '[ANNOUCEMENT] Set Annoucement List'
export const SET_IS_CONNECTING = '[ANNOUCEMENT] Set Is Connecting Socket'
export const SET_BROADCAST_MESSAGE = '[ANNOUCEMENT] Set Broadcast Message'
export const SET_DOWNLOAD_LINK = '[ANNOUCEMENT] Set Download Link'
export const SET_GAME_TIP = '[ANNOUCEMENT] Set Game Tip'
export const NONE = '[ANNOUCEMENT] None'

const initialState = {
  list: [],
  listCount: 0,
  games: [],
  broadcast: null,
  isConnecting: false,
  isConnectSocket: false,
  downloadLink: ''
}

export default function (state = initialState, { payload, type, annoucement: init }) {
  switch (type) {
    case SET_ANNOUCEMENTS:
      return { ...state,
        list: payload.announcements,
        listCount: payload.announcements.length
      }

    case SET_GAME_TIP:
      return { ...state,
        games: payload.games
      }

    case SET_IS_CONNECTING: 
      return {
        ...state,
        isConnecting: true 
      }

    case socketActionTypes.SOCKET_OPEN:
      return {
        ...state,
        isConnectSocket: true,
        isConnecting: false 
      }

    case socketActionTypes.SOCKET_MESSAGE:
      return {...state,
        list: [ payload.data, ...state.list ],
        listCount: state.listCount + 1
      }
    
    case SET_BROADCAST_MESSAGE:
      const { broadcast } = payload
      return { ...state,
        broadcast
      }

    case SET_DOWNLOAD_LINK:
    const { downloadLink } = payload
      return { ...state,
        downloadLink
      }

    case INIT:
      return { ...state,
        ...init }

    case NONE:
      return { ...state }

    default:
      return state
  }
}