import { INIT } from './app.reducer'
export const SET_AUTH_FLOW = '[AUTH] Set Auth Flow'
export const SET_USER = '[AUTH] Set User'
export const USER_LOGOUT = '[AUTH] User Logout'

const initialState = {
  phoneNumber: null,
  isRegistered: null,
  hasOTP: null,
  id: null,
  fullName: null
}

export default function (state = initialState, { payload, type , auth: init }) {
  switch (type) {
    case SET_AUTH_FLOW:
      const { isRegistered, hasOTP, phoneNumber } = payload
      return { ...state,
        isRegistered, hasOTP, phoneNumber }
        
    case SET_USER:
      const { id, fullName } = payload
      return { ...state,
        id, fullName }

    case USER_LOGOUT:
      return {
        ...initialState
      }

    case INIT:
      return { ...state,
        ...init }

    default:
      return state
  }
}