import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl';

import { P, H3, ModalHeader, ModalBody, ModalFooter, Form, Submit, Input } from '@framework'
import { updateProfile } from '@store/actions/auth.action'

const INPUT_BIRTHDATE = 'birthdate'

class BirthdateModal extends Component {
  constructor (props) {
    super(props)

    this.state = {
      [INPUT_BIRTHDATE]: null
    }
  }

  async handleSubmit () {
    const { updateProfile, modal } = this.props
    
    updateProfile({
      birthdate: this.state[INPUT_BIRTHDATE]
    })

    modal.hide()
  }

  handleInputChange (state, event) {
    this.setState({ [state]: event.target.value })
  }

  render () {
    return (
      <Form onSubmit={this.handleSubmit.bind(this)}>
        <ModalHeader color='brand-primary'>
          <H3><FormattedMessage id='component.modal.birthdate.header' /></H3>
        </ModalHeader>
        <ModalBody>
          <P><FormattedMessage id='component.modal.birthdate.body' /></P>
          <Input label={<FormattedMessage id='component.modal.birthdate.header' />}
            type='date'
            value={this.state.birthdate} 
            onChange={this.handleInputChange.bind(this, INPUT_BIRTHDATE)}/>
        </ModalBody>
        <ModalFooter>
          <Submit color='brand-success'>
            <FormattedMessage id='common.input.submit' />
          </Submit>
        </ModalFooter>
      </Form>
    )
  }
}

export default connect(
  null,
  dispatch => bindActionCreators({
    updateProfile
  }, dispatch)
)(BirthdateModal)
