import React, { Component } from 'react'
import ReactMarkdown from 'react-markdown'
import breaks from 'remark-breaks'
import _uniqueId from 'lodash.uniqueid'
import { Flex, H2, P, Image, Button } from '@framework'

import './Blog.scss'

class PromotionCard extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isExpanded: false
    }
  }

  toggleExpand () {
    const { isExpanded } = this.state

    this.setState({
      isExpanded: !isExpanded
    })
  }

  render () {
    const { title, subtitle, content, headerImage } = this.props.data
    const { isExpanded } = this.state
    return (
      <Flex column className='promotion-card'>
        <Flex>
          <Image src={headerImage} alt={title} className='__headerImage' />
          <Flex column>
            <H2>{title}</H2>
            <P>{subtitle}</P>
            <Button className='blog-btn' color='brand-primary'
              onClick={this.toggleExpand.bind(this)}>
              {isExpanded ? 'Less' : 'More'} Information
            </Button>

          </Flex>
        </Flex>
     
        { isExpanded && <ReactMarkdown className='markdown-body' source={content} plugins={[breaks]} /> }
      </Flex>
    )
  }
}

const BlogCards = {
  'promotion': PromotionCard
}

export default function Blog(props) {
  const { posts, type } = props;
  if (!type) {
    console.warn('Warning: Blog required props "type"')
    return null
  }

  if (!BlogCards[type]) {
    console.warn('Warning: Invalid type for Blog')
    return null
  }

  const BlogCard = BlogCards[type]

  return (
    posts.length ?
    posts.map((data) => (
      <div className='component-blog-list' key={_uniqueId()}>
        <BlogCard data={data} />
      </div>
    ))
    :
    <H2>No posts yet</H2>
  )
}
