import ProfileOverviewPage from '@pages/Profile/Overview'
import DepositOverviewPage from '@pages/Deposit/Overview'
import WithdrawalOverviewPage from '@pages/Withdrawal/Overview'
import GameWheelPage from '@pages/Game/Wheel'
import Promotion from '@pages/Promotion'
import Error404 from '@pages/Error404'

export const workspaceRoutes = [
  {
    path: '/manage/profile',
    component: ProfileOverviewPage
  }, {
    path: '/manage/wallet/deposit',
    component: DepositOverviewPage
  }, {
    path: '/manage/wallet/withdrawal',
    component: WithdrawalOverviewPage
  }, {
    path: '/manage/game/wheel',
    component: GameWheelPage
  }, {
    path: '/manage/game/bonus',
    component: Promotion
  }, {
    component: Error404
  }
]
