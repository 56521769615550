import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  authInterceptedAxios,
  appInterceptedAxios
} from './interceptors'

export function requestFileUploadUrl({ url }) {
  return authInterceptedAxios({
    method: 'get',
    url
  })
}

export function uploadFile({ uploadUrl, data } = {}) {
  return appInterceptedAxios({
    method: 'put',
    url: uploadUrl,
    data,
    withCredentials: false,
    isInterruptive: true,
    loadKey: <FormattedMessage id='api.uploader.loader.uploadFile' />,
    headers: {
      'Content-Type': 'image/jpeg'
    }
  });
}