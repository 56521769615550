import React, { Component } from 'react'
import { Switch } from 'react-router-dom'
import { authRoutes } from '@routes'
import { renderRoutes } from '@helpers/routes'
import { Page } from '@framework'
import { OurValue } from '@components'

class BasePage extends Component {
  render () {
    return (
      <div className='scope-auth-base'>
        <Page>
          <OurValue />
        </Page>
        <Switch>
          {renderRoutes(authRoutes)}
        </Switch>
      </div>
    )
  }
}

export default BasePage
