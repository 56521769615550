import KitchenSinkPage from '@pages/Kitchen/Sink'
import { BasePage } from '@pages/Auth'
import Workspace from '@pages/Workspace'
import Error404 from '@pages/Error404'
import HomePage from '@pages/Home'

export const appRoutes = [
  {
    path: '/kitchen/sink',
    component: KitchenSinkPage
  }, {
    path: '/auth',
    component: BasePage
  }, {
    path: '/manage',
    component: Workspace
  }, {
    path: '/',
    component: HomePage
  }, {
    component: Error404
  }
]
