import { OPEN_MODAL, CLOSE_MODAL, UPDATE_ERROR } from '../reducers/app.reducer'

export function openModal ({ modal, config, bundleMsg }) {
  return dispatch => {
    dispatch({
      type: OPEN_MODAL,
      payload: { modal, config, bundleMsg }
    })
  }
}

export function closeModal() {
  return dispatch => {
    dispatch({
      type: CLOSE_MODAL
    })
  }
}

export function updateError (err) {
  return dispatch => {
    dispatch({
      type: UPDATE_ERROR,
      payload: err
    })
  }
}
