import React, { Component } from 'react'
import ReactPlayer from 'react-player'

class VideoModal extends Component {
  render () {
    return (
      <ReactPlayer
        style={{ backgroundColor: 'black' }}
        width='100%'
        playing={true}
        controls={true}
        playsinline={true}
        volume={1}
        muted={true}
        url='https://ops-public-game-images.s3-ap-southeast-1.amazonaws.com/videos/spins2win_tutorial.mp4' />
    )
  }
}

export default VideoModal
