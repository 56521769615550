import { INIT } from './app.reducer'

export const SET_PROMOTIONS = '[PROMOTION] Set Promotion Blog List'
export const SET_CAROUSEL_IMAGE = '[PROMOTION] Set Carousel Image List'

const initialState = {
  posts: [],
  carouselImages: [], 
}

export default function (state = initialState, { payload, type, promotion: init }) {
  switch (type) {
    case SET_PROMOTIONS:
      return {
        ...state,
        posts: payload.posts
      }

      case SET_CAROUSEL_IMAGE:
      return {
         ...state,
        carouselImages: payload.carouselImages
      }

    case INIT:
      return { 
        ...state,
        ...init }

    default:
      return state
  }
}