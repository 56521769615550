import React, { Component } from 'react'
import { H3, P, ModalHeader, ModalBody, ModalFooter, Button } from '@framework'
import { FormattedMessage } from 'react-intl';

class ResetPasswordSucessModal extends Component {
  render () {
    const { modal } = this.props
    return (
      <div>
        <ModalHeader color='brand-primary'>
          <H3><FormattedMessage id='component.modal.resetpassword.header' /></H3>
        </ModalHeader>
        <ModalBody>
          <P><FormattedMessage id='component.modal.resetpassword.body' /></P>
        </ModalBody>
        <ModalFooter>
          <Button color='brand-success' onClick={() => modal.hide()}>OK</Button>
        </ModalFooter>
      </div>
    )
  }
}

export default ResetPasswordSucessModal
