import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl';
import { H3, P, ModalHeader, ModalBody, ModalFooter, Button } from '@framework'
import { setGamePrize } from '@store/actions/wallet.action'
import { bindActionCreators } from 'redux'

export const PRIZE_SCORE_CODE = 'win-score';
export const FREE_SPIN_CODE = 'free-spin';
export const TRY_AGAIN_CODE = 'try-again'

class SpinSuccessModal extends Component {

  constructor(props) {
    super(props)
    this.state = {
      prize: {}
    }
  }

  componentDidMount() {
    const { gameList, wonItem } = this.props
    const wonGameIndex = gameList.findIndex(game => game.gameId === wonItem.gameId)
    const wonItemIndex = gameList[wonGameIndex].gameItems.findIndex(item => item.itemId === wonItem.itemId)
    const prize = gameList[wonGameIndex].gameItems[wonItemIndex]
    this.setState({ prize })
    this.setPrize({ prize, game: gameList[wonGameIndex]});
  }

  setPrize({ prize, game }) {
    const { setGamePrize } = this.props;
    switch(prize.prizeCode) {
      case PRIZE_SCORE_CODE:
        setGamePrize({ winScore: prize.score })
        break;
      case FREE_SPIN_CODE:
        setGamePrize({ winPoint: game.pointsToPlay })
        break;
      default:
        break;
    }
  }

  renderHeader() {
    const { prize }  = this.state;
    switch(prize.prizeCode) {
      case PRIZE_SCORE_CODE:
      case FREE_SPIN_CODE:
        return <H3><FormattedMessage id='component.modal.spinsuccess.header.win'/></H3>
      case TRY_AGAIN_CODE:
         return <H3><FormattedMessage id='component.modal.spinsuccess.header.lose'/></H3>
      default:
        return null
    }
  }

  renderBody() {
    const { prize }  = this.state;
    switch(prize.prizeCode) {

      case PRIZE_SCORE_CODE:
        return (
          <P>
            <FormattedMessage id='component.modal.spinsuccess.body.win'/> {prize.score} <FormattedMessage id='component.modal.spinsuccess.body.win-score'/>
          </P>
        )
      case FREE_SPIN_CODE:
        return <P><FormattedMessage id='component.modal.spinsuccess.body.win'/> <FormattedMessage id='component.modal.spinsuccess.body.win-freespin'/></P>
      case TRY_AGAIN_CODE:
         return <P><FormattedMessage id='component.modal.spinsuccess.body.lose'/></P>
      default:
        return null
    }
  }

  render () {
    const { modal} = this.props
    return (
      <div>
        <ModalHeader color='brand-primary'>
          {this.renderHeader()}
        </ModalHeader>
        <ModalBody>
          {this.renderBody()}
        </ModalBody>
        <ModalFooter>
          <Button color='brand-secondary' onClick={() => modal.hide()}>OK</Button>
        </ModalFooter>
      </div>
    )
  }
}

export default connect(
  ({ game }) => ({
    gameList: game.list,
    wonItem: game.wonItem
  }),  dispatch => bindActionCreators({
    setGamePrize
  }, dispatch)
)(SpinSuccessModal)
