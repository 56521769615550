import React from 'react'
import classNames from 'classnames'
import { Flex, Image } from '@framework'

import './OurValue.scss'

export default (props) => {
  const { padded } = props

  return (
    <Flex className={classNames('component-our-value', {
      '--padded': padded
    })}>
      <Flex>
        <Image className='attribute' src='https://ops-ui-static.s3-ap-southeast-1.amazonaws.com/icons/Time.png' />
      </Flex>
      <Flex>
        <Image className='attribute' src='https://ops-ui-static.s3-ap-southeast-1.amazonaws.com/icons/24hr.png' />
      </Flex>
      <Flex>
        <Image className='attribute' src='https://ops-ui-static.s3-ap-southeast-1.amazonaws.com/icons/Secure.png' />
      </Flex>
      <Flex>
        <Image className='attribute' src='https://ops-ui-static.s3-ap-southeast-1.amazonaws.com/icons/Cash.png' />
      </Flex>
    </Flex>
  )
}
