import React from 'react'
import { FormattedMessage } from 'react-intl'
import { appInterceptedAxios, authInterceptedAxios } from './interceptors'

export function sendOtp ({ phoneNumber, isResend }) {
  return appInterceptedAxios({
    method: 'post',
    url: '/auth/send-otp',
    data: { phoneNumber, isResend },
    loadKey: <FormattedMessage id='api.auth.loader.sendOtp' />,
    isInterruptive: true
  })
}

export function verifySignUp ({ phoneNumber, otp, referralPhoneNumber }) {

  return appInterceptedAxios({
    method: 'post',
    url: '/auth/verify-sign-up',
    data: { phoneNumber, otp, referralPhoneNumber },
    loadKey: <FormattedMessage id='api.auth.loader.verifySignUp' />,
    isInterruptive: true
  })
}

export function login ({ phoneNumber, otp }) {
  return appInterceptedAxios({
    method: 'post',
    url: '/auth/login',
    data: { phoneNumber, otp },
    loadKey: <FormattedMessage id='api.auth.loader.login' />,
    isInterruptive: true
  })
}

export function refreshToken () {
  return appInterceptedAxios({
    method: 'post',
    url: '/auth/refresh-token',
    loadKey: <FormattedMessage id='api.auth.loader.refreshToken' />
  })
}

export function getProfile() {
  return authInterceptedAxios({
    method: 'get',
    url: '/auth/get-profile',
    loadKey: <FormattedMessage id='api.auth.loader.getProfile' />,
    isInterruptive: true
  })
}

export function updateProfile ({ birthdate, familyName, givenName }) {
  return authInterceptedAxios({
    method: 'post',
    url: '/auth/update-user',
    data: {
      birthdate,
      family_name: familyName,
      given_name: givenName
    },
    loadKey: <FormattedMessage id='api.auth.loader.updateProfile' />,
    isInterruptive: true
  })
}

export function logout () {
  return authInterceptedAxios({
    method: 'get',
    url: '/auth/logout',
    loadKey: <FormattedMessage id='api.auth.loader.logout' />,
    isInterruptive: true
  })
}