import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedMessage } from "react-intl";

import { Carousel } from "react-responsive-carousel";
import cookie from "cookie";

import { Flex, Image, Button, FlyingText, Link } from "@framework";
import { getAccountInfo, listBonusType } from "@store/actions/wallet.action";
import { WalletBalance } from "@components";
import { getAnnouncement } from "@store/actions/announcement.action";
import { listCarouselImages } from "@store/actions/promotion.action";
import { PLAYER_ACCESS_TOKEN } from "@constants/cookies";
import { openModal } from "@store/actions/app.action";
import { LANGUAGE_MODAL } from "@components/ModalWrapper";
import { MALAY, ENGLISH, CHINESE } from "@constants/locale";
import { getDownloadLink } from "@store/actions/announcement.action";
import { retrieveLanguage } from "@store/actions/locale.action";

import { MAINTENANCE_MODAL } from "@components/ModalWrapper";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./AuthPrompt.scss";

class AuthPrompt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
    };
  }

  toggleLanguageModal() {
    const { openModal } = this.props;
    openModal({
      modal: LANGUAGE_MODAL,
    });
  }

  componentDidMount() {
    const { isChangeLang } = this.props;
    this.props.retrieveLanguage();
    this.props.openModal({ modal: MAINTENANCE_MODAL });
    if (!isChangeLang) {
      this.props.getAnnouncement();
      this.props.listCarouselImages();
      this.props.getDownloadLink();

      const hasAccessToken = !!cookie.parse(document.cookie)[
        PLAYER_ACCESS_TOKEN
      ];
      if (hasAccessToken) {
        this.props.listBonusType();
      }
    }
  }

  flagUrl() {
    const { locale } = this.props;
    switch (locale) {
      default:
      case ENGLISH:
        return "/flags/US.png";
      case CHINESE:
        return "/flags/China.png";
      case MALAY:
        return "/flags/Malaysia.png";
    }
  }

  renderCarousel() {
    const { images } = this.props;
    return (
      <Carousel
        showIndicators={false}
        infiniteLoop
        autoPlay
        emulateTouch
        showThumbs={false}
        showStatus={false}
        className="presentation-mode"
      >
        {images.length ? (
          images.map((image, index) => (
            <Image key={index} className="header-image" src={image} />
          ))
        ) : (
          <Image
            className="header-image"
            src="https://img.thedailybeast.com/image/upload/c_crop,d_placeholder_euli9k,h_1440,w_2560,x_0,y_0/dpr_1.5/c_limit,w_908/fl_lossy,q_auto/v1522190406/180327-lorenz-fortnite-lede_hwpkls"
          />
        )}
      </Carousel>
    );
  }

  render() {
    const hasAccessToken = !!cookie.parse(document.cookie)[PLAYER_ACCESS_TOKEN];
    const { hasCognitoId, broadcast, getAccountInfo, link } = this.props;
    const isAuthenticated = hasAccessToken || hasCognitoId;
    return (
      <div className="component-auth-prompt">
        <header>
          <Flex className="wallet-balance">
            <WalletBalance small />
          </Flex>
          <Link to="/" className="header" onClick={getAccountInfo}>
            <Image
              className="__logo"
              src="https://ops-ui-static.s3-ap-southeast-1.amazonaws.com/LOGO.gif"
            />
          </Link>
          <Flex
            column
            className="__language"
            onClick={this.toggleLanguageModal.bind(this)}
          >
            <Flex>
              <span className="__text">Language: </span>
              <Image className="__flag-selected" src={this.flagUrl()} />
            </Flex>
          </Flex>
        </header>
        <Flex>
          {this.renderCarousel()}
          <div className="call-to-action-container">
            {!isAuthenticated ? (
              <Flex className="__cta">
                <Button color="brand-secondary" to="/auth/login">
                  <FormattedMessage id="component.authprompt.button.register" />
                </Button>
                <a
                  className="btn --brand-secondary"
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="component.authprompt.button.download" />
                </a>
                <Button color="brand-secondary" to="/auth/login">
                  <FormattedMessage id="component.authprompt.button.login" />
                </Button>
              </Flex>
            ) : (
              <Flex className="__cta">
                <a
                  className="btn --brand-secondary download-btn"
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="component.authprompt.button.download" />
                </a>
              </Flex>
            )}
          </div>
        </Flex>
        {!!broadcast && <FlyingText>{broadcast}</FlyingText>}
      </div>
    );
  }
}

export default connect(
  ({ announcement, wallet, promotion, locale }) => ({
    hasCognitoId: !!wallet.cognitoId,
    broadcast: announcement.broadcast,
    images: promotion.carouselImages,
    isChangeLang: locale.isChangeLang,
    locale: locale.language.locale,
    link: announcement.downloadLink,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        openModal,
        getAccountInfo,
        getAnnouncement,
        listCarouselImages,
        getDownloadLink,
        listBonusType,
        retrieveLanguage,
      },
      dispatch
    )
)(AuthPrompt);
