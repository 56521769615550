import { UPDATE_LANGUAGE } from '../reducers/locale.reducer'

import cnLang from '@app/locale/cn'
import enLang from '@app/locale/en'
import myLang from '@app/locale/my'
import { MALAY, ENGLISH, CHINESE } from '@constants/locale';

function getLanguage(country) {
  let language = {}
  switch (country) {
    case MALAY:
      language = {
        ...myLang,
        messages: {
          ...enLang.messages,
          ...myLang.messages
        }
      }
      break;
    case CHINESE:
      language = {
        ...cnLang,
        messages: {
          ...enLang.messages,
          ...cnLang.messages
        }
      }
      break;
    case ENGLISH:
    default:
      language = enLang
  }

  return language
}

export function updateLanguage (country) {
  return dispatch => {
    localStorage.setItem('lang', country);
    const language = getLanguage(country)

    dispatch({
      type: UPDATE_LANGUAGE,
      payload: { language }
    });
  };
}

export function retrieveLanguage() {
  return dispatch => {
    const country = localStorage.getItem('lang');
    const language = getLanguage(country) 

    dispatch({
      type: UPDATE_LANGUAGE,
      payload: { language }
    });
  }
}
