import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl';

import { H2, Flex, Button } from '@framework'

import { syncScore } from '@store/actions/wallet.action'

import './WalletBalance.scss'
class WalletBalance extends Component {
  render () {
    const { score, small } = this.props

    if (small) {
      return (
        <Flex column className='component-wallet --small'>
          <div><FormattedMessage id='component.walletbalance.label.short' />:</div>
          <div>MYR {score !== null && score > 0 ? score.toFixed(2) : '-.--'}</div>
        </Flex>
      )
    }
    return (
      <Flex>
        <Flex column>
          <H2><FormattedMessage id='component.walletbalance.label.long' /> MYR {score !== null && score >= 0 ? score.toFixed(2) : '-.--'}</H2>
        </Flex>
        <Flex noGrow>
          <div>
            <Button color='brand-primary' onClick={this.props.syncScore}><i className="fas fa-sync" /></Button>
          </div>
        </Flex>
      </Flex> 
    )
  }
}

export default connect(
  ({ wallet }) => ({
    score: wallet.score
  }), 
  dispatch => bindActionCreators({
    syncScore
  }, dispatch)
)(WalletBalance)
