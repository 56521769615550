import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import cookie from 'cookie'

import { Page } from '@framework'
import { RealTimeBoard } from '@components/Tables'
// import { VIDEO_MODAL } from '@components/ModalWrapper'
import { openModal } from '@store/actions/app.action'

import { PLAYER_ID_TOKEN } from '@constants/cookies';

class HomePage extends Component {

  componentDidMount() {
    const hasAccessToken = !!cookie.parse(document.cookie)[PLAYER_ID_TOKEN];
    if(!hasAccessToken) {

      // setTimeout(() => {
      //   this.props.openModal({ modal: VIDEO_MODAL });
      // }, 3000);
    }
  }

  render () {
    return (
      <Page branded className='scope-home'>
        <Helmet title='Spins2win: Home' />
        <RealTimeBoard />
      </Page>
    )
  }
}

export default connect(
  null,
  dispatch => bindActionCreators({
    openModal,
  }, dispatch)
)(HomePage)
