import { BANKS } from '@constants/banks'
import { INIT } from './app.reducer'
import { USER_LOGOUT } from './auth.reducer'

export const SET_TRANSACTIONS = '[WALLET] Set Transaction List'
export const APPEND_TRANSACTIONS = '[WALLET] Append Transaction List'
export const SET_WITHDRAWAL_REQUESTS = '[WALLET] Set Withdrawal Requests'
export const WITHDRAWAL = '[WALLET] Withdrwal'
export const SET_SCORE = '[WALLET] Set Score'
export const SET_ACCOUNT = '[WALLET] Set Account'
export const SET_BONUSES = '[WALLET] Set Bonuses'
export const SPEND_GAME_POINT = '[WALLET] Spend Game Point'
export const SET_GAME_PRIZE = '[WALLET] Set Game Prize'
export const SAVE_DEPOSIT_FORM = '[WALLET] Save Deposit Form'
export const CLEAR_DEPOSIT_FORM = '[WALLET] Clear Deposit Form'
export const SET_BONUS_TYPE = '[WALLET] Set Bonus Type'
export const REMOVE_BONUS_TYPE = '[WALLET] Remove Bonus Type'

const initialState = {
  transactions: [],
  transactionsNextKey: null,
  withdrawalRequests: [],
  score: null,
  gamePoint: 0,
  lastUpdatedScore: null,
  banks: [],
  cognitoId: null,
  phoneNumber: null,
  playerUsername: null,
  createdAt: null,
  bonuses: [],// deposit bonus
  bonusTypes: [], // referral. rebate bonus
  birthdateCode: null,
  hasUsedBonus: null,
  depositForm: {
    bank: BANKS[0].label,
    score: 0,
    token: ''
  }
}

export default function (state = initialState, { payload, type, wallet: init }) {
  switch (type) {
    case SET_TRANSACTIONS: {
      const { transactions, nextKey } = payload
      const history = transactions.map(trans => ({
        ...trans,
        scoreToDisplay: `RM ${trans.score.toFixed(2)}`
      }))
      return {
        ...state,
        transactions: history,
        transactionsNextKey: nextKey
      }
    }

    case APPEND_TRANSACTIONS: {
      const { transactions, nextKey } = payload
      const appendHistory = [ ...state.transactions, ...transactions ].map(trans => ({
        ...trans,
        scoreToDisplay: `RM ${trans.score.toFixed(2)}`
      }))
      return {
        ...state,
        transactions: appendHistory,
        transactionsNextKey: nextKey
      }
    }

    case WITHDRAWAL: {
      const { score } = payload;
      return {
        ...state,
        score: state.score - score
      }
    }

    case SET_WITHDRAWAL_REQUESTS:
      const { withdrawalRequests } = payload
      return { ...state,
        withdrawalRequests }

    case SET_SCORE: {
      const { score, turnover } = payload
      return { ...state,
        score,
        turnover,
        lastUpdatedScore: new Date() }
    }

    case SET_ACCOUNT:
      const { banks, balance, point = 0, cognitoId, phoneNumber, createdAt, turnover, birthdateCode, playerUsername, hasUsedBonus } = payload
      return { ...state,
        banks: banks ? banks : [],
        score: balance,
        gamePoint: point,
        cognitoId,
        phoneNumber,
        createdAt,
        lastUpdatedScore: new Date(),
        turnover,
        birthdateCode,
        playerUsername,
        hasUsedBonus
      }

    case SET_BONUSES:
      const { bonuses } = payload
      return { ...state,
        bonuses }

    case SPEND_GAME_POINT:
      const { pointSpent } = payload
      return { ...state,
        gamePoint: state.gamePoint - pointSpent }

    case SET_GAME_PRIZE:
      const { winScore, winPoint } = payload
      return {
        ...state,
        gamePoint: state.gamePoint + winPoint,
        score: state.score + winScore
      }

    case SAVE_DEPOSIT_FORM:
      const { bank, score, token } = payload
      return {
        ...state,
        depositForm: {
          ...state.depositForm,
          bank,
          score,
          token
        }
      }

    case CLEAR_DEPOSIT_FORM:
      return {
        ...state,
        depositForm: {
          ...initialState.depositForm
        }
      }

    case SET_BONUS_TYPE:
      const bonusTypes  = payload.bonusTypes.map((item) =>({
        ...item,
        type: `${item.type}Bonus`
      }))

      return {
        ...state,
        bonusTypes
      }

    case REMOVE_BONUS_TYPE:
      const { id } = payload;

      const filteredBonusTypes = state.bonusTypes.filter(({ id: itemId }) => itemId !== id)

      return {
        ...state,
        bonusTypes: filteredBonusTypes
      }

    case USER_LOGOUT:
      return {
        ...initialState
      }

    case INIT:
      return { ...state,
        ...init }


    default:
      return state
  }
}