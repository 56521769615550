import React, { Component }  from 'react'
import { Image } from '@framework'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getDownloadLink } from '@store/actions/announcement.action'

import './DownloadButton.scss'

class DownloadButton extends Component {

  componentDidMount() {
    this.props.getDownloadLink();
  }

  render () {
    const { link } = this.props;
    return (
      <a href={link} target='_blank' rel='noopener noreferrer' className='component-download'>
        <Image className='icon' src='data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAACNElEQVR42u3XA6hgQRQG4LWtzJWy1rZt27aNLK1t20xa27ZtzPurU001xvPUd4XTf4XJkN7SW3qL2CZ2LF0NJsJmuATv4R+NL8FmWl8jMUNlg+6wB/4DM/Cftu8JuWOGqwybgXnYDPVihOsBt4H5ojoDQoYbDJ+BGdhHmArVGxMiXHuLcE+5/d4ZhhzgE6605WW9ye371OJy13MJlwk2AIsZkGyG3LYBqwJLjICkp23AbRbF3ysCfjCssccmXDGLl/AOyAht4ApX4zH0hjyw3vBlXsM0YCODG3sSTW/g9ivPTdeATBR+OW07U/PQTTQNuEBR5CLkpe0O0rIZilojaZvTNJ8HLskeFtOApxUBe9A2pWARdytMhcyicGQZlKDlgyS1L5kGfKUI2JS2mSlYN5OrMVywfhqt6yx72EwD/nQJSNbAMsm6ObRvJ8n6f6YB73gEVJlL+7byPYMbDALOsgzHn8GWvvdgf4OACxwCTuMCej3FZYFJNKNtFjoEnMoF9HoPZoavkiLtFGfQJ+B/qGrzuRsKTKAr97U5C1cFrsMNGl8lZ6Au7dve61tMRfLCb0GhrQF+grcL6na3q6J+oS6H4g71SsIayf9gNrefVnX/4gd8Jd84XwV+Kn48KvhcjkJwD1gEn6FbBt9G/4d3I4QbGLLrmQ/2BOwXt/aIo7wnO3FPt4t1Bg+Yd9D8MMyiv/wfNkNFyJghsRp9ccpAH1gNN+ALPIVjMBvqQ5EM6S29pdCWAMBwJoQfdIvbAAAAAElFTkSuQmCC' />
      </a>
    )
  }
}

export default connect(
  ({ announcement }) => ({
    link: announcement.downloadLink,
  }),
  dispatch => bindActionCreators({ getDownloadLink }, dispatch)
)(DownloadButton)
