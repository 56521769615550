import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'react-router-redux'

import { ConnectedIntlProvider } from '@framework'
import { history, store } from './store'

import './index.css'
import '@framework/reset.css'
import App from './App'
// Uncomment the following line for PWA
// import registerServiceWorker from './registerServiceWorker'

ReactDOM.render(
  <Provider store={store} >
    <ConnectedIntlProvider >
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </ConnectedIntlProvider>
  </Provider>,
  document.getElementById('root')
)
// registerServiceWorker()
