export default {
  label: 'Bahasa Malaysia',
  locale: 'ms',
  messages: {
    'common.input.submit': 'Hantar',
    'common.input.cancel': 'Batal',
    'component.form.bindbank.input.bankname': 'Nama Bank',
    'component.form.bindbank.input.accountnumber': 'Nombor Account',
    'component.form.bindbank.input.accountname': 'Nama Account',
    'component.form.bindbank.input.submit': 'Tambah Bank',
    'component.modal.birthdate.header': 'Isi tarikh hari lahir',
    'component.modal.birthdate.body': 'Sila kemas kini tarikh lahir untuk menerima promosi',
    'component.modal.birthdate.input.date': 'tarikh lahir',
    'component.modal.resetpassword.header': 'Kata laluan telah berjaya kemas kini',
    'component.modal.resetpassword.body': 'Kata laluan baru akan sampai sebentar lagi..',
    'component.modal.spinsuccess.header.win': 'Tahniah!',
    'component.modal.spinsuccess.header.lose': 'Amat menyesal!',
    'component.modal.spinsuccess.body.win': 'Spin cantik! Anda telah menang',
    'component.modal.spinsuccess.body.win-score': 'skor',
    'component.modal.spinsuccess.body.win-freespin': 'sekali game putar free',
    'component.modal.spinsuccess.body.lose': 'Jangan kecewa! Anda akan lagi bertuah di kali seterusnya',
    'component.modal.language.header': 'Sila pilih bahasa',
    'component.table.realtimeboard.tab.deposit': 'Deposit',
    'component.table.realtimeboard.tab.withdrawal': 'Pengeluaran',
    'component.table.realtimeboard.tab.games': 'Tips',
    'component.table.realtimeboard.tab.sub.title': 'Masa sebenar',
    'component.table.realtimeboard.tab.tip.table.notice': '10 game yang terbanyak pemain akan dipaparkan terlebih dahulu di sistem',
    'component.table.realtimeboard.tab.tip.refresh': 'Segarkan dalam setiap jam',
    'component.table.realtimeboard.tab.tip.table.column.1': 'Permainan',
    'component.table.realtimeboard.tab.tip.table.column.2': 'Pengguna Dalam Talian',
    'component.table.realtimeboard.tab.tip.table.column.3': 'Kadar jackpot',
    'component.table.realtimeboard.tab.tip.table.column.4': 'Kadar Permainan Percuma',
    'component.authprompt.label.language': 'Bahasa',
    'component.authprompt.button.register': 'Daftar',
    'component.authprompt.button.login': 'Log Masuk',
    'component.authprompt.button.download': 'Muat Turun',
    'component.livechat.button': '24 Hr Live Chat',
    'component.spinwheel.balance': 'Baki permainan',
    'component.spinwheel.point': 'Baki permainan yang ada',
    'component.walletbalance.label.short': 'Baki',
    'component.walletbalance.label.long': 'Baki anda adalah',
    'component.modal.otp.header': 'Kepada ahli Spins2Win',
    'component.modal.otp.msg': 'Jika kod OTP tiada sampai, sila tunggu 5 minit sebentar untuk cuba lagi',
    'component.modal.has.otp.msg': 'Anda boleh menggunakan OTP sebelumnya untuk log masuk, sila dapatkan dari msg lama anda',
    'page.app.tab.deposit': 'Deposit',
    'page.app.tab.withdraw': 'Pengeluaran',
    'page.app.tab.wheel': 'Roda',
    'page.app.tab.promotion': 'Promosi',
    'page.app.tab.profile': 'Profil',
    'page.login.helmet.title': 'Spins2win: Daftar / Log Masuk',
    'page.login.body.title': 'Log Masuk Ahli',
    'page.login.input.phone': 'Nombor Telefon',
    'page.login.input.submit': 'Dapatkan OTP',
    'page.verifyotp.text.header': 'Isikan nombor password sekali (OTP)',
    'page.verifyotp.text.p': 'Untuk dapat OTP, sila chek SMS',
    'page.verifyotp.text.resend.countdown': 'Hantar sekali lagi masuk',
    'page.verifyotp.text.resend': 'Hantar sekali lagi',
    'page.verifyotp.input.otp': 'Kata laluan sekali',
    'page.verifyotp.input.referral': 'Nombor telefon perujuk',
    'page.verifyotp.input.submit': 'Log masuk',
    'page.deposit.bonus.warning': 'Tiada Bonus yang akan ditunjukkan kerana jumlah baki melebihi',
    'page.deposit.cash.notice.txt1': 'Silakan Tanya ',
    'page.deposit.cash.notice.txt2': 'Detail Bank Account ',
    'page.deposit.cash.notice.txt3': 'Melalui Live Chat.',
    'page.deposit.cash.input.deposit': 'Jumlah deposit',
    'page.deposit.cash.input.reference': 'ID Rujukan',
    'page.deposit.cash.input.date': 'Tarikh deposit',
    'page.deposit.online.input.deposit': 'Jumlah deposit',
    'page.deposit.online.input.nobonus': 'Tiada bonus',
    'page.deposit.overview.tab.online': 'Deposit Online',
    'page.deposit.overview.tab.cash': 'Deposit Tunai',
    'page.deposit.overview.text.minimum': 'Jumlah deposit minimum',
    'page.deposit.overview.text.maximum': 'Jumlah deposit maximum',
    'page.profile.profile.title': 'Kemas Kini Profil Anda',
    'page.profile.history.input.startdate': 'Tarikh Bermula',
    'page.profile.history.input.enddate': 'Tarikh akhir',
    'page.profile.history.input.loadmore': 'Tambah lagi',
    'page.profile.bonus.label.empty': 'Belum ada bonus',
    'page.profile.history.input.search': 'Cari',
    'page.profile.overview.tab.profile': 'Profil',
    'page.profile.overview.tab.transaction': 'Transaksi',
    'page.profile.profile.input.id': 'Mega ID',
    'page.profile.bonus.input.claim': 'Claim',
    'page.profile.bonus.input.withdraw': 'Withdraw',
    'page.profile.profile.input.password': 'Kata laluan Mega',
    'page.profile.profile.button.changepassword': 'Ubah kata laluan Mega',
    'page.profile.profile.button.logout': 'Log keluar',
    'page.profile.profile.input.error': 'Kata laluan mesti kena 6 huruf, sekurang-kurangnya satu huruf besar dan tiada huruf urutan',
    'page.withdrawal.overview.tab.withdraw': 'Pengeluaran',
    'page.withdrawal.overview.tab.bindbank': 'Mengikat Bank',
    'page.withdrawal.overview.text.minimum': 'Jumlah pengeluaran minimum',
    'page.withdrawal.overview.text.maximum': 'Jumlah pengeluaran seharian maximum',
    'page.withdrawal.withdrawal.input.amount': 'Jumlah pengeluaran',
    'page.withdrawal.withdrawal.input.submit': 'Permohonan pengeluaran',
    'page.withdrawal.withdrawal.text.instruction': 'Ikat satu akaun bank untuk mohon pengeluaran',
    'api.agent.loader.addBank': 'Mengikat Bank',
    'api.agent.loader.getAccountInfo': 'Sedang ambil info akaun',
    'api.agent.loader.listTransactions': 'Penyenaraian transaksi',
    'api.agent.loader.syncScore': 'Penyegerakan skor',
    'api.agent.loader.resetPassword': 'Tetap semula kata laluan',
    'api.agent.loader.listPublicTransactions': 'Sedang ambil data transaksi yang waktu terkini',
    'api.auth.loader.sendOtp': 'Memohon kata laluan sekali',
    'api.auth.loader.verifySignUp': 'Sedang berdaftar',
    'api.auth.loader.login': 'Sedang log masuk',
    'api.auth.loader.refreshToken': 'Sedang mengemas kini token',
    'api.auth.loader.getProfile': 'Sedang membina ruang kerja anda',
    'api.auth.loader.updateProfile': 'Sedang kemas kini profil',
    'api.auth.loader.logout': 'Sedang log keluar',
    'api.game.loader.listGames': 'Sedang ambil senarai game',
    'api.game.loader.playGame': 'Sedang pusing roda',
    'api.game.loader.gametips': 'Listing Game Tips',
    'api.messaging.loader.getAnnouncement': 'Sedang ambil pengumuman',
    'api.payment.loader.deposit': 'Sedang sambung ke Help2Pay',
    'api.payment.loader.depositCash': 'Sedang permohonan deposit',
    'api.payment.loader.requestWithdrawal': 'Sedang permohonan pengeluaran',
    'api.payment.loader.listWithdrawalRequest': 'Sedang menyenaraikan permohonan pengeluaran',
    'api.payment.loader.listBonuses': 'Sedang menyenaraikan pelbagai bonus',
    'api.payment.loader.listOrders': 'Sedang menyenaraikan transaksi',
    'api.sync.loader.syncTurnOver': 'Penyegerakan turnover',
    'api.uploader.loader.uploadFile': 'Sedang muat naik file',
    'e001': 'Bentuk nombor telefon tidak betul. Contoh 0127543455',
    'e002': 'Kod OTP tidak sah',
    'e003': 'Jumlah deposit minimum ialah RM50',
    'e004': 'Jumlah deposit minimum ialah RM30',
    'e005': 'Jumlah pengeluaran minimum ialah RM50',
    'e006': 'Baki tidak mencukupi untuk pengeluaran',
    'e007': 'Point tidak mencukupi untuk main game',
    'e008': 'Pengguna rujukan tidak sah',
    'e009': 'Permintaan pengeluaran dibenarkan pada satu masa',
    'e010': 'Lucky Wheel tidak sah buat masa ini',
    'e011': 'Sila Lampirkan gambar',
    'e012': 'Sistem turun pada masa ini',
  }
}