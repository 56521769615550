import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import { FormattedMessage } from 'react-intl';

import { Tabular, Tag, Input, Form, Submit, Button, P } from '@framework'
import { listTransactions, listMoreTransactions } from '@store/actions/wallet.action'
import { justifyChar } from '@helpers/utils';


class WalletHistoryPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      startDate: moment(new Date()).startOf('month').format('YYYY-MM-DD'),
      endDate: moment(new Date()).endOf('month').format('YYYY-MM-DD')
    }
  }

  handleStartDateChange (ev) {
    this.setState({ startDate: ev.target.value })
  }

  handleEndDateChange (ev) {
    this.setState({ endDate: ev.target.value })
  }

  renderSelectedPage () {
    const { transactions } = this.props

    return (
      <div>
        <Tabular
          data={transactions}
          bold
          staticColumns={[
            {
              Cell: row => {
                const { remark, status, createdAt, reason } = row.value
                return [
                  <p className='header'>
                    <span>{ justifyChar(remark)}</span>
                    <span className='status'>
                      <Tag color={status === 'success' ? 'success' : 'danger'}> {justifyChar(status)}</Tag>
                    </span>
                  </p>,
                  <P>
                    {status === 'fail' && <span>Reason: {justifyChar(reason)}</span>}
                  </P>,
                  <p className='subheader'>{moment(new Date(createdAt)).format('YYYY-MM-DD, h:mm:ss a')}</p>
                ]
              }
            }
          ]}
          dynamicColumns={[
            {
              Header: "Score",
              accessor: "scoreToDisplay",
            }
          ]} />
      </div>
    )
  }

  render () {
    const { listMoreTransactions, nextKey, listTransactions} = this.props;
    const { startDate, endDate } = this.state
    return (
      <div>
        <Form onSubmit={listMoreTransactions.bind(this, this.state)} >
          <Input type='date' value={startDate}
            label={<FormattedMessage id='page.profile.history.input.startdate' />} 
            onChange={this.handleStartDateChange.bind(this)} />
          <Input type='date' value={endDate}
            label={<FormattedMessage id='page.profile.history.input.enddate' />}
            onChange={this.handleEndDateChange.bind(this)} />

            <Button color='brand-primary'
             onClick={listTransactions.bind(this, this.state)}>
             <FormattedMessage id='page.profile.history.input.search' />
            </Button>
          {this.renderSelectedPage()}
          { nextKey
            ? <Submit block color='brand-primary' >
                <FormattedMessage id='page.profile.history.input.loadmore' />
              </Submit>
            : '' }
        </Form>
     
      </div>
    )
  }
}

export default connect(
  ({ wallet }) => ({
    transactions: wallet.transactions,
    nextKey : wallet.transactionsNextKey,
    withdrawalRequests: wallet.withdrawalRequests
  }), 
  dispatch => bindActionCreators({
    listTransactions,
    listMoreTransactions
  }, dispatch)
)(WalletHistoryPage)
