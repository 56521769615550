import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router-dom'
import { FormattedMessage } from 'react-intl';

import { verifyOtp, requestOtp } from '@store/actions/auth.action'
import { Page, Form, Submit, H1, P, Input, Flex } from '@framework'

import './VerifyOTP.scss'

class VerifyOTPPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      ableToResend: false,
      second: 600
    }
  }

  componentDidMount(){
    this.startTimer();
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  startTimer(){
    this.myInterval = setInterval(() => {
      const { second } = this.state
      if (second > 0) {
        this.setState({ second: second - 1 })
      }
      if(second === 0) {
        clearInterval(this.myInterval)
        this.setState({ ableToResend: true })
      }
    }, 1000)
  }


  resendOtp() {
    const{ phoneNumber } = this.props;
    this.setState({
      second: 600,
      ableToResend: false
    });
    this.startTimer();
    this.props.requestOtp({ phoneNumber, resend: true });
  }

  handleInputChange (state, event) {
    this.setState({ [state]: event.target.value })
  }

  render () {
    if (!this.props.phoneNumber) {
      return <Redirect to='/auth/login' />
    }
    return (
      <Page paperWhite>        
        <Helmet title='Spins2win: One Time Password' />
        <H1><FormattedMessage id='page.verifyotp.text.header' /></H1>
        <P><FormattedMessage id='page.verifyotp.text.p' /></P>
        <Form onSubmit={() => this.props.verifyOtp(this.state)}>
          <Input label={<FormattedMessage id='page.verifyotp.input.otp' />} autoFocus
            onChange={this.handleInputChange.bind(this, 'otp')} />
            {
              !this.props.isRegistered ?
              <Input label={<FormattedMessage id='page.verifyotp.input.referral' />} 
              onChange={this.handleInputChange.bind(this, 'referralPhoneNumber')} /> :
              ''
            }
            <Flex row>
              <Submit color='brand-primary'>
                <FormattedMessage id='page.verifyotp.input.submit' />
              </Submit>
              <Submit onClick={() => this.resendOtp()}
                disabled={!this.state.ableToResend} color='brand-secondary-rounded'>
                {this.state.ableToResend ? 
                <div>
                  <FormattedMessage id='page.verifyotp.text.resend'/> 
                </div>:
                <div>
                  <FormattedMessage id='page.verifyotp.text.resend.countdown'/> ({this.state.second })
                </div>
                }
              </Submit>
            </Flex>
          
        </Form>
      </Page>
    )
  }
}

export default connect(
  ({ auth }) => ({
    phoneNumber: auth.phoneNumber,
    isRegistered: auth.isRegistered,
  }), 
  dispatch =>
  bindActionCreators(
    { verifyOtp, requestOtp },
    dispatch
  )
)(VerifyOTPPage)
