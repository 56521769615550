import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Helmet } from 'react-helmet'
import { FormattedMessage } from 'react-intl';

import { Page, PageHeader, PageFooter, Breadcrumb, Crumb, ToggleGroup, Tab, P } from '@framework'
import { WalletBalance, OurValue } from '@components'
import { listBonuses } from '@store/actions/wallet.action'

import DepositCashPage from './Cash'
import DepositOnlinePage from './Online'

import './Overview.scss'

const TAB_ONLINE = 'tab-online'
const TAB_CASH = 'tab-cash'

class DepositOverviewPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      page: TAB_ONLINE,
      minimum: {
        [TAB_ONLINE]: 50,
        [TAB_CASH]: 30
      }
    }
  }

  componentDidMount () {
    const { bonuses, listBonuses } = this.props

    if (!bonuses.length) {
      listBonuses();
    }
  }

  handleToggle (selected) {
    this.setState({
      page: selected,
    })
  }

  renderSelectedPage () {
    const { page } = this.state
    switch (page) {
      case TAB_ONLINE:
        return <DepositOnlinePage />
      case TAB_CASH:
        return <DepositCashPage />
      default:
        return null
    }
  }

  render () {
    return (
      <div>
        <OurValue padded />
        <Page paperWhite className='page-deposit'>
          <PageHeader>
            <ToggleGroup branded
              selected={this.state.page}
              onChange={(selected) => this.handleToggle(selected)}>
              <Tab value={TAB_ONLINE}><FormattedMessage id='page.deposit.overview.tab.online' /></Tab>
              <Tab value={TAB_CASH}><FormattedMessage id='page.deposit.overview.tab.cash' /></Tab>
            </ToggleGroup>
          </PageHeader>
          <Helmet title='Spins2win: Deposit' />
          <Breadcrumb>
            <Crumb to='/'>Home</Crumb>
            <Crumb>Deposit</Crumb>
          </Breadcrumb>
          
          {
            this.state.page === TAB_CASH && 
            <P className='warn-color'>
              <FormattedMessage id='page.deposit.cash.notice.txt1'/>
              <span className='warn-bold'>
                <FormattedMessage id='page.deposit.cash.notice.txt2'/>
              </span>
              <FormattedMessage id='page.deposit.cash.notice.txt3'/>
            </P>
          }

          <WalletBalance />
          {this.renderSelectedPage()}
          <PageFooter className='footer'>
            <P>
              <FormattedMessage id='page.deposit.overview.text.minimum' />
              : MYR {this.state.minimum[this.state.page]}
            </P>
            <P>
              <FormattedMessage id='page.deposit.overview.text.maximum' />
              : MYR 50,000
            </P>
          </PageFooter>
      </Page>
    </div>
    )
  }
}

export default connect(
  ({ wallet }) => ({
    bonuses: wallet.bonuses
  }), 
  dispatch => bindActionCreators({
    listBonuses
  }, dispatch)
)(DepositOverviewPage)
