import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import './Link.scss'

export const Link = (props) => {
  const { className = '', children, ...attr } = props
  return (
    <RouterLink className={`af-link ${className}`}
      {...attr} >
      {children}
    </RouterLink>
  )
}
