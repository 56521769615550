import { combineReducers } from 'redux'
import appReducer from './app.reducer'
import announcementReducer from './announcement.reducer'
import authReducer from './auth.reducer'
import walletReducer from './wallet.reducer'
import gameReducer from './game.reducer'
import promotionReducer from './promotion.reducer'
import localeReducer from './locale.reducer'

export default combineReducers({
  app: appReducer,
  announcement: announcementReducer,
  auth: authReducer,
  wallet: walletReducer,
  game: gameReducer,
  promotion: promotionReducer,
  locale: localeReducer
})
