import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import passwordValidator from 'password-validator';
import { FormattedMessage } from 'react-intl';

import { H1, Input, Button, Form, Submit } from '@framework'
import { BindBankForm } from '@components/Forms'
import { resetPassword } from '@store/actions/wallet.action'
import { logout } from '@store/actions/auth.action'
import './Profile.scss'

const schema = new passwordValidator();

class ProfilePage extends Component {

  constructor(props){
    super(props);
    this.state ={
      password: '',
      validate: true
    }
    schema
      .is().min(6)
      .has().uppercase()
      .has().lowercase()
      .has().digits()
  }

  handleInputChange (state, event) {
    this.setState({ 
      [state]: event.target.value,
      validate: schema.validate(event.target.value)
    })
  }


  render () {
    return (
      <div>
        <H1><FormattedMessage id='page.profile.profile.title'/></H1>
        <BindBankForm />
        <Input label={<FormattedMessage id='page.profile.profile.input.id' />}
          disabled
          value={this.props.username} />

        <Form onSubmit={this.props.resetPassword.bind(this, this.state)}>
            <Input label={<FormattedMessage id='page.profile.profile.input.password' />}
              value={this.state.password}
              onChange={this.handleInputChange.bind(this, 'password')}>
              {!this.state.validate ? <span className='error'><FormattedMessage id='page.profile.profile.input.error'/> </span> : ''}
            </Input>
            <Submit color='brand-danger'
              disabled={!this.state.password || !this.state.validate }> 
              <FormattedMessage id='page.profile.profile.button.changepassword' />
            </Submit>
        </Form>
        <Button color='brand-secondary-rounded'
          onClick={this.props.logout}>
          <FormattedMessage id='page.profile.profile.button.logout' />
        </Button>
      </div>
    )
  }
}

export default connect(
  ({ wallet }) => ({
    username: wallet.playerUsername
  }),
  dispatch => bindActionCreators({
    resetPassword,
    logout
  }, dispatch)
)(ProfilePage)
