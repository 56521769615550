import React, { Component } from 'react'
import { connect } from 'react-redux'
import { H3, P, ModalHeader, ModalBody, ModalFooter, Button } from '@framework'
import { FormattedMessage } from 'react-intl';

class MessageModal extends Component {
  render () {
    const { modal, bundleMsg } = this.props
    const { header, message, closeButtonText } = bundleMsg;
    return (
      <div>
        <ModalHeader color='brand-primary'>
          <H3><FormattedMessage id={header} /></H3>
        </ModalHeader>
        <ModalBody>
          <P> <FormattedMessage id={message} /></P>
        </ModalBody>
        <ModalFooter>
          <Button color='brand-success' target='_blank' onClick={() => modal.hide()}>{closeButtonText}</Button>
        </ModalFooter>
      </div>
    )
  }
}

function mapStatetoProps ({ app }) {
  return {
    bundleMsg: app.modal.bundleMsg
  }
}

export default connect(mapStatetoProps)(MessageModal)


