import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Helmet } from 'react-helmet'

import { Page, Breadcrumb, Crumb, Section, Carousel } from '@framework'
import { Spinwheel } from '@components'

import { listGames } from '@store/actions/game.action'

class GameWheelPage extends Component {
  componentDidMount () {
    const { games, listGames } = this.props
    
    if (!games.length) {
      listGames()
    }
  }

  renderGames () {
    const { games } = this.props
    return (
      <Carousel>
        {games.length && games.map(game => <Spinwheel game={game} />)}
      </Carousel>
    )
  }

  render () {
    return (
      <Page paperWhite className='page-game-wheel'>
        <Helmet title='Spins2win: Spin Wheel' />
        <Breadcrumb>
          <Crumb to='/'>Home</Crumb>
          <Crumb>Spin Wheel</Crumb>
        </Breadcrumb>
        <Section>
          {this.renderGames()}
        </Section>
    </Page>
    )
  }
}

export default connect(
  ({ game }) => ({
    games: game.list
  }), 
  dispatch => bindActionCreators({
    listGames
  }, dispatch)
)(GameWheelPage)
