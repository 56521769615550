import { INIT } from './app.reducer'
import { USER_LOGOUT } from './auth.reducer'

export const SET_GAMES = '[GAME] Set Game List'
export const SET_WON_ITEM = '[GAME] Set Won Item'
export const RESET_WON_ITEM = '[GAME] Reset Won Item'

const initialState = {
  list: [],
  wonItem: {}
}

export default function (state = initialState, { payload, type, game: init }) {
  switch (type) {
    case SET_GAMES:
      const { games } = payload
      return { ...state,
        list: games }

    case SET_WON_ITEM:
      const { gameId, itemId } = payload
      return { ...state,
        wonItem: {
          gameId,
          itemId
        }
      }

    case RESET_WON_ITEM:
      return { ...state,
        wonItem: {}
      }

    case USER_LOGOUT:
      return {
        ...initialState
      }

    case INIT:
      return { ...state,
        ...init }

    default:
      return state
  }
}