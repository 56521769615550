import React, { Component } from 'react'
import {
  requestFileUploadUrl,
  uploadFile
} from '@store/services/uploader.api'
import { Input } from '@framework'

class Uploader extends Component {
  constructor (props) {
    super(props);

    const { s3PresignUrl } = props;

    this.image = '';
    this.s3PresignUrl = s3PresignUrl;

    this.handleChange = this.handleChange.bind(this);
    this.upload = this.upload.bind(this);
  }

  componentDidMount () {
    const { onMount } = this.props

    if (typeof onMount === 'function') {
      onMount(this)
    } else {
      console.warn('Use onMount props to get uploader instance eg. onMount={ref => this.uploader = ref}')
    }
  }

  componentWillUnmount () {
    this.props.onMount(undefined)
  }

  readImageAsDataUrl(file) {
    const reader = new FileReader();

    reader.onload = (event) => {
      this.image = event.target.result;
    }

    reader.readAsDataURL(file);
  }

  convertImageToBlobData() {
    let binary = atob(this.image.split(',')[1]);
    let array = [];

    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }

    return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
  }

  async requestUploadUrl() {
    const { uploadUrl } = await requestFileUploadUrl({ url: this.s3PresignUrl });

    return uploadUrl;
  }

  async handleChange(event) {
    this.fileInputTarget = event.target;
    const file = event.target.files[0];
    this.readImageAsDataUrl(file);
  }

  async upload() {
    if(!this.image) {
      return
    }

    const uploadUrl = await this.requestUploadUrl();
    const blobData = this.convertImageToBlobData();

    try {
      await uploadFile({ uploadUrl, data: blobData })
      this.fileInputTarget.value = null;
      return uploadUrl.split('?')[0];
    } catch (error) {
      throw error
    }
  }

  render () {
    const { accept } = this.props;

    return (
      <Input type='file'
        accept={accept}
        onChange={this.handleChange}
      />
    )
  }
}

export default Uploader;
