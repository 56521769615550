import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl';

import { Flex, Form, Input, Select, Submit, P } from '@framework'
import { deposit } from '@store/actions/wallet.action'

import { HELP2PAY_BANKS } from '@constants/banks'

const INPUT_BANK = 'bankCode'
const INPUT_DEPOSIT_AMOUNT = 'score'
const INPUT_BONUS_TOKEN = 'token'

class DepositOnlinePage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      [INPUT_BANK]: 0,
      [INPUT_DEPOSIT_AMOUNT]: undefined,
      [INPUT_BONUS_TOKEN]: ''
    }
  }

  handleInputChange (state, event) {
    this.setState({ [state]: event.target.value })
  }

  handleSubmit() {
    if(this.state[INPUT_BANK] && this.state[INPUT_BANK] !== 0) {
      this.props.deposit(this.state)
    }
  }

  renderBonuses () {
    const { bonuses, hasUsedBonus } = this.props

    return bonuses.map((bonus, i) => (
      (!bonus.isOneTime || !hasUsedBonus) &&
      <Input key={i} type='radio'
      name='bonus' value={bonus.token}
      label={`${bonus.description}`}
      checked={this.state[INPUT_BONUS_TOKEN] === bonus.token}
      onChange={this.handleInputChange.bind(this, INPUT_BONUS_TOKEN)} /> 
    ))
  }

  render () {
    const { language, bonuses } = this.props;
    const placeholder = language.messages['page.deposit.online.input.deposit']
    const copyRight = language.messages['page.deposit.online.help2pay.copy.right'].replace('$year', new Date().getFullYear())
    return (
      <div className='scope-wallet-deposit'>
        <Flex column>
          <img width={100} src='/help2pay.png' alt=''/>
          <P className='warn-color'> {copyRight} &#169;</P>
        </Flex>
        <Form onSubmit={() => this.handleSubmit()}>
          <Select value={this.state[INPUT_BANK]} onChange={this.handleInputChange.bind(this, INPUT_BANK)}>
            {HELP2PAY_BANKS.map(({code, label}) => (<option key={code} value={code}>{label}</option>))}
          </Select>
          <Input placeholder={placeholder}
            value={this.state[INPUT_DEPOSIT_AMOUNT]}
            type='number'
            step='0.01'
            onChange={this.handleInputChange.bind(this, INPUT_DEPOSIT_AMOUNT)} />
          <Flex column>
            <Input type='radio' name='bonus'
              value={''} label={<FormattedMessage id='page.deposit.online.input.nobonus' />}
              checked={this.state[INPUT_BONUS_TOKEN] === ''}
              onChange={this.handleInputChange.bind(this, INPUT_BONUS_TOKEN)} />
              {
                bonuses !== null && bonuses.length > 0 ?
                this.renderBonuses() :
                <P className='warn-bonus'> <FormattedMessage id='page.deposit.bonus.warning'/></P>
              }
          </Flex>
          <Submit color='brand-success'>
            <FormattedMessage id='common.input.submit' />
          </Submit>
        </Form>
      </div>
    )
  }
}

export default connect(
  ({ wallet, locale }) => ({
    bonuses: wallet.bonuses,
    balance: wallet.score,
    hasUsedBonus: wallet.hasUsedBonus,
    language: locale.language
  }), 
  dispatch => bindActionCreators({
    deposit
  }, dispatch)
)(DepositOnlinePage)
