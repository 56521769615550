import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl';

import { Form, Input, Select, Submit, Flex, InfiniteDatePicker, P } from '@framework'
import { Uploader } from '@components'
import { depositCash } from '@store/actions/wallet.action'
import { updateError } from '@store/actions/app.action'

import { CASH_BANKS } from '@constants/banks'

const INPUT_BANK = 'bank'
const INPUT_DEPOSIT_AMOUNT = 'score'
const INPUT_BONUS_TOKEN = 'token'
const INPUT_REFERENCE_ID = 'referenceId'
const INPUT_DEPOSIT_DATE = 'depositedDate'

class DepositCashPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      [INPUT_BANK]: 0,
      [INPUT_DEPOSIT_AMOUNT]: undefined,
      [INPUT_BONUS_TOKEN]: '',
      [INPUT_REFERENCE_ID]: '',
      [INPUT_DEPOSIT_DATE]: new Date().toISOString()
    }
  }

  handleInputChange (state, event) {
    this.setState({ [state]: event.target.value })
  }

  handleDateChange (date) {
    this.setState({ [INPUT_DEPOSIT_DATE]: new Date(date).toISOString() })
  }

  async handleSubmit () {
    const { depositCash } = this.props
    if(this.state[INPUT_BANK] && this.state[INPUT_BANK] !== 0) {
      const imageLink = await this.uploader.upload();
      if(!imageLink) {
        this.props.updateError({
          response: {
            data: {
              message: 'e011'
            }
          }
        })
        return;
      }
  
      depositCash({
        ...this.state,
        imageLink
      })
    } 
  }

  renderBonuses () {
    const { bonuses, hasUsedBonus } = this.props

    return bonuses.map((bonus, i) => (
      (!bonus.isOneTime || !hasUsedBonus) &&
      <Input key={i} type='radio'
      name='bonus' value={bonus.token}
      label={`${bonus.description}`}
      checked={this.state[INPUT_BONUS_TOKEN] === bonus.token}
      onChange={this.handleInputChange.bind(this, INPUT_BONUS_TOKEN)} /> 
    ))
  }

  render () {
    const { language, bonuses } = this.props;
    const depositPlaceHolder = language.messages['page.deposit.cash.input.deposit']
    return (
      <div>
        <Form onSubmit={this.handleSubmit.bind(this)}>
          <Select value={this.state[INPUT_BANK]} onChange={this.handleInputChange.bind(this, INPUT_BANK)}>
            {CASH_BANKS.map(({code, label}) => (<option key={code} value={code}>{label}</option>))}
          </Select>
          <Input placeholder={depositPlaceHolder}
            value={this.state[INPUT_DEPOSIT_AMOUNT]}
            type='number'
            step='0.01'
            onChange={this.handleInputChange.bind(this, INPUT_DEPOSIT_AMOUNT)} />
          <Input label={<FormattedMessage id='page.deposit.cash.input.reference' />}
            value={this.state[INPUT_REFERENCE_ID]}
            onChange={this.handleInputChange.bind(this, INPUT_REFERENCE_ID)} />
          <InfiniteDatePicker
            label={<FormattedMessage id='page.deposit.cash.input.date' />}
            isSingleDateSelect
            onSelect={this.handleDateChange.bind(this)} />
          <Uploader
            onMount={ref => (this.uploader = ref)}
            s3PresignUrl='/payment/request-upload-receipts-url'
            accept='image/jpeg' />
          <Flex column>
            <Input type='radio' name='bonus'
              value={''} label='No Bonus' checked={this.state[INPUT_BONUS_TOKEN] === ''}
              onChange={this.handleInputChange.bind(this, INPUT_BONUS_TOKEN)} />
              {
                bonuses !== null && bonuses.length > 0 ?
                this.renderBonuses() :
                <P className='warn-bonus'> <FormattedMessage id='page.deposit.bonus.warning'/></P>
              }
          </Flex>
          <Submit color='brand-success'>
            <FormattedMessage id='common.input.submit' />
          </Submit>
        </Form>
      </div>
    )
  }
}

export default connect(
  ({ wallet, locale }) => ({
    bonuses: wallet.bonuses,
    depositForm: wallet.depositForm,
    balance: wallet.score,
    hasUsedBonus: wallet.hasUsedBonus,
    language: locale.language
  }), 
  dispatch => bindActionCreators({
    depositCash,
    updateError
  }, dispatch)
)(DepositCashPage)
