import { INIT } from './app.reducer'
import enLang from '@app/locale/en';

export const UPDATE_LANGUAGE = '[LOCALE] Set Language';

const initialState = {
  language: enLang,
  isChangeLang: false
};

export default function reducer(state = initialState, { payload, type, locale: init }) {
  switch (type) {
    case UPDATE_LANGUAGE:
      return {
        ...state,
        language: payload.language,
        isChangeLang: true
      };

    case INIT:
      return {
        ...state,
        ...init
      }

    default:
      return state;
  }
}