import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl';

import { Form, Input, Select, Submit } from '@framework'
import { WalletBalance } from '@components'
import { requestWithdrawal } from '@store/actions/wallet.action'

import './Withdrawal.scss'

const INPUT_BANK = 'bank'
const INPUT_WITHDRAWAL_AMOUNT = 'score'

class WalletWithdrawalPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      [INPUT_WITHDRAWAL_AMOUNT]: 0
    }
  }

  handleInputChange (state, event) {
    this.setState({ [state]: event.target.value })
  }

  handleBankChange (state, event) {
    this.setState({
      ...JSON.parse(event.target.value)
    })
  }

  render () {
    const { addedBanks, language } = this.props
    const placeholder = language.messages['page.withdrawal.withdrawal.input.amount']
    return (
      <div>
        <WalletBalance />
        {
          addedBanks.length > 0 ?
          <Form onSubmit={() => this.props.requestWithdrawal(this.state)}>
            <Select value={this.state.value} onChange={this.handleBankChange.bind(this, INPUT_BANK)}>
              <option>- Select an account -</option>
              {addedBanks.map(bank => 
                (<option key={bank.bankCode+bank.bankAccountNumber}
                  value={JSON.stringify(bank)}>
                  {bank.bankCode} | {bank.bankAccountNumber} | {bank.bankAccountName}
                </option>))}
            </Select>
            <Input placeholder={placeholder}
              type='number'
              step='0.01'
              onChange={this.handleInputChange.bind(this, INPUT_WITHDRAWAL_AMOUNT)} />
            <Submit color='brand-success'>
              <FormattedMessage id='page.withdrawal.withdrawal.input.submit' />
            </Submit>
          </Form> :
          <div>
            <FormattedMessage id='page.withdrawal.withdrawal.text.instruction' />
          </div>
        }
      </div>
    )
  }
}

export default connect(
  ({ wallet, locale }) => ({
    addedBanks: wallet.banks,
    language: locale.language
  }),
  dispatch => bindActionCreators({
    requestWithdrawal
  }, dispatch)
)(WalletWithdrawalPage)
